export interface MinMax {
    max?: number;
    min?: number;
    value: number;
}

/**
 * Given a range, bounds any number value to that range, returning the bound number.
 * If the value isn't a number, returns undefined.
 * @param param0 MinMax
 */
export const minMax = ({ max, min, value }: MinMax) => {
    if (!isNaN(value)) {
        if (min !== undefined && max !== undefined) {
            return Math.max(min, Math.min(max, value));
        } else if (min !== undefined) {
            return Math.max(min, value);
        } else if (max !== undefined) {
            return Math.min(max, value);
        } else {
            return value;
        }
    }
};
