import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';
import { SyntheticEvent, useEffect, useState } from 'react';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import { useStoreNeededModal } from '@tb-core/components/container/store-needed-modal';
import { toDollars } from '@tb-core/components/simple/currency';
import Svg from '@tb-core/components/simple/svg';
import Indicator from '@tb-core/components/styled/indicator';
import MiniCart from '@tb-core/components/styled/mini-cart';
import Tooltip from '@tb-core/components/styled/tooltip';
import { getTotalCartQuantity } from '@tb-core/helpers/products/cart';
import { isStoreSelected } from '@tb-core/helpers/store';
import { useOptimizelyFlag } from '@tb-core/hooks/analytics/use-optimizely-flag';
import { useClientSideCartContext } from '@tb-core/hooks/client-side-cart/use-client-side-cart-context';
import useCartIconContext from '@tb-core/hooks/use-cart-icon-context';
import useCartTooltipContext from '@tb-core/hooks/use-tooltip-context';

import fullToolbar from './full-toolbar.module.scss';

export interface CartIconProps {
    cartLabel?: string;
    isFullToolbar?: boolean;
    title: string;
}

const CartIcon = ({
    cartLabel,
    isFullToolbar = true,
    title
}: CartIconProps) => {
    const isMiniCartEnabled = useOptimizelyFlag('mini_cart');

    const [showSubTotal, setShowSubTotal] = useState<boolean>(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

    const { local } = useClientSideCartContext();
    const { cartLineItems, subTotal } = useCartIconContext();
    const {
        isCartLoading,
        setIsCartLoading,
        showTooltip,
        setShowTooltip
    } = useCartTooltipContext();
    const { openStoreNeededModal } = useStoreNeededModal();

    const cartQuantity = local ? getTotalCartQuantity(local.cart) : 0;

    // SubTotal should only display if the Tooltip provider is true,
    // and the total has updated.
    useEffect(() => {
        if (subTotal && showTooltip) {
            setIsCartLoading(false);
            setShowSubTotal(true);

            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setTimeoutId(
                setTimeout(() => {
                    setShowTooltip(false);
                    setShowSubTotal(false);
                }, 3500)
            );
        }
    }, [subTotal]);

    const onClick = (e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) => {
        track('Clicked Cart Icon', { cta: cartLabel });

        if (!isStoreSelected()) {
            e.preventDefault();
            openStoreNeededModal({ isBackShown: true });
        }
    };

    return (
        <>
            {isFullToolbar && <span className={fullToolbar.divider}>|</span>}
            <div
                className={classNames(fullToolbar['cart-container'], {
                    [fullToolbar.disable]: isCartLoading
                })}
            >
                <SafeAnchorWithGaEvent
                    gaDataLayerConfig={{
                        'Analytics-Action': 'Top Nav',
                        'Analytics-Value': 'Show Cart'
                    }}
                    href="/order/cart"
                    onClick={onClick}
                >
                    <>
                        <Svg
                            className={fullToolbar.icon}
                            svgId="icon-shopping-cart"
                            title={title}
                        />
                        {cartQuantity > 0 && (
                            <Indicator
                                className={fullToolbar.indicator}
                                value={cartQuantity}
                            />
                        )}
                        {cartLabel && (
                            <span className={fullToolbar.label}>
                                {cartLabel}
                            </span>
                        )}
                        <Tooltip
                            className={classNames([
                                fullToolbar.tooltip,
                                {
                                    [fullToolbar.active]: showSubTotal,
                                    [fullToolbar.hideOnDesktop]: isMiniCartEnabled
                                }
                            ])}
                            value={toDollars(subTotal)}
                        />
                    </>
                </SafeAnchorWithGaEvent>

                {isMiniCartEnabled && (
                    <MiniCart
                        cartLineItems={cartLineItems}
                        cartQuantity={cartQuantity}
                        local={local}
                        onClick={onClick}
                        total={subTotal}
                    />
                )}
            </div>
        </>
    );
};

export default CartIcon;
