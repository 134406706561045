import { datadogLogs } from '@datadog/browser-logs';
import { emptyMenu } from '@tb-core/components/context/menu';
import { devProxyResolve } from '@tb-core/helpers/browser/dev-proxy-resolve';
import Fetch from '@tb-core/helpers/fetch';
import { interpolate } from '@tb-core/helpers/interpolate';
import { setProviderUrl } from '@tb-core/helpers/utils/optimizely/set-provider-url';
import { nextMenuUrl } from '@tb-core/next/api/urls';
import { Menu } from '@tb-core/types/products';

export default async function getMenu(
    storeId: string,
    host = '',
    serverSide = false
): Promise<Menu> {
    let res: Response;
    const nextMenuProviderUrl = setProviderUrl(nextMenuUrl);
    const url = serverSide
        ? interpolate(setProviderUrl(nextMenuProviderUrl), { storeId })
        : devProxyResolve(nextMenuProviderUrl, {
              storeId
          });

    try {
        res = await Fetch({
            host,
            url
        });
    } catch (e) {
        console.error(
            serverSide
                ? 'Menu server-side request failed!'
                : 'Menu request failed!',
            e
        );
        datadogLogs.logger.log('getMenu', { e }, 'error');
        return emptyMenu;
    }

    if (!res.ok) {
        return emptyMenu;
    }

    // @TODO Update response type.
    return res.json();
}
