import {
    FocusEventHandler,
    FormEvent,
    FormEventHandler,
    forwardRef,
    useState
} from 'react';

import { useInputValue, useMinMax } from '@tb-core/hooks';
import { InputProps, InputValue } from '@tb-core/types/elements/form';

export type NumberInputOnChange = (nextValue?: number) => void;

export interface NumberInputProps extends Omit<InputProps, 'onChange'> {
    onChange?: NumberInputOnChange;
}

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
    ({ max, min, onChange, type = 'number', value = 0, ...props }, ref) => {
        const getMinMax = useMinMax({
            max: Number(max),
            min: Number(min)
        });
        const [currentValue, setCurrentValue] = useInputValue(getMinMax(value));
        const [backupValue, setBackupValue] = useState<InputValue>();

        const handleInput:
            | FocusEventHandler<HTMLInputElement>
            | FormEventHandler<HTMLInputElement> = (
            e: FormEvent<HTMLInputElement>
        ) => {
            const inputValue = (e.target as HTMLInputElement).value;
            const isBlur = e.type === 'blur';
            const valueAsString =
                inputValue || (isBlur ? (backupValue as string) : '');
            // reason call parseInt 1st instead of directly calling getMinMax() is
            // getMinMax('') returns 1 because isNaN('') === false.
            const valueAsNumber = parseInt(valueAsString, 10);

            if (valueAsNumber > 0) {
                const minMaxValue = getMinMax(valueAsNumber);
                // save value
                onChange?.(minMaxValue);
                // persist value
                setCurrentValue(minMaxValue);
            } else {
                // blank or zero ( don't save )
                setCurrentValue('');
            }
        };

        // onInput : prevent user from typing in a decimal point character into this input field
        const onInput: FormEventHandler<HTMLInputElement> = e => {
            const inputField = e.target as HTMLInputElement;
            const newValue = inputField.value.replace(/[^0-9]/g, '');
            inputField.value = ''; // this line seems like does nothing but it keeps cursor to right side of #
            inputField.value = newValue;
        };

        const onFocus: FocusEventHandler<HTMLInputElement> = e => {
            // change current value to placeholder value (grayed out value) that only show if user not enter new value
            e.target.placeholder = value as string;
            setCurrentValue('');
            // save value that will be used later if user makes this field blank then blurs this field.
            setBackupValue(value);
        };

        return (
            <input
                {...props}
                max={max}
                min={min}
                onBlur={handleInput}
                onChange={handleInput}
                onFocus={onFocus}
                onInput={onInput}
                ref={ref}
                type={type}
                value={currentValue}
            />
        );
    }
);

export default NumberInput;
