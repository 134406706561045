import classNames from 'classnames';
import { FC, HTMLProps } from 'react';

import styles from './styles.module.scss';

interface TbIndicatorUiProps {
    value: number | string;
}

const TbIndicatorUi: FC<TbIndicatorUiProps & HTMLProps<HTMLSpanElement>> = ({
    className,
    value
}) => (
    <span className={classNames([className, styles.indicator])}>{value}</span>
);

export default TbIndicatorUi;
