import React, { Fragment } from 'react';

import { contentType } from '@tb-core/helpers/content';
import { fieldsOf } from '@tb-core/helpers/fields';
import { Props, RealObject } from '@tb-core/types';
import { ContentfulEntryFields } from '@tb-core/types/contentful';

export type ListItem = <T extends ContentfulEntryFields>(
    // @TODO Remove replace use of any with a more appropriate solution.
    // This causes TS complaint in ui card-set component (w/o use of any).
    props: T & ListItemProps & any
) => JSX.Element;

export interface ListItemProps {
    index: number;
}

export type ListType =
    | 'ol'
    | 'ul'
    | false
    | ((props: { children?: any } & Props) => JSX.Element);

interface ListProps {
    list: RealObject[];
    listItem?: ListItem;
    tag?: ListType;
}

const ListUtil = ({ list, listItem, tag = 'ul' }: ListProps) => {
    // Outer tag (ol | ul | Fragment)
    let Tag;

    switch (tag) {
        case false:
            Tag = Fragment;
            break;
        default:
            Tag = tag;
    }

    return (
        <Tag>
            {(list || []).map((item, i: number) => {
                const ListItem =
                    (['ol', 'ul'] as ListType[]).indexOf(tag) >= 0
                        ? 'li'
                        : Fragment;
                const props: ContentfulEntryFields = {
                    ...fieldsOf(item),
                    contentType: contentType((item || {}).sys),
                    raw: item
                };

                return (
                    (listItem && (
                        <ListItem key={i}>
                            {listItem({ ...props, index: i })}
                        </ListItem>
                    )) ||
                    null
                );
            })}
        </Tag>
    );
};

export default ListUtil;
