import classNames from 'classnames';

import SafeAnchor from '@tb-core/components/behavior/safe-anchor';
import Svg from '@tb-core/components/simple/svg';
import Indicator from '@tb-core/components/styled/indicator';
import LogoAside from '@tb-core/components/styled/logo-aside';
import UserDropdownAside from '@tb-core/components/styled/user-dropdown-aside';

import styles from './styles.module.scss';

export interface LoginToolbarProps {
    alertCount: number;
    displayUserDropdown: boolean;
    firstName?: string;
    handleClick: () => void;
    isClickableLogo?: boolean;
    isLoggedIn?: boolean;
    loggedOutHrefYum: string;
    loginClass: string;
    loginLabel: string;
    logoSvgId: string;
    toggleDropdown: () => void;
    userDropdownMenu?: any;
}

const LoginToolbar = ({
    alertCount,
    displayUserDropdown,
    firstName,
    handleClick,
    isClickableLogo = true,
    isLoggedIn = false,
    loginClass,
    loginLabel,
    loggedOutHrefYum,
    logoSvgId,
    toggleDropdown,
    userDropdownMenu
}: LoginToolbarProps) => {
    const href = loggedOutHrefYum;

    return (
        <div className={styles['login-toolbar']}>
            {!isLoggedIn ? (
                <>
                    <LogoAside
                        className={styles.absolute}
                        handleClick={handleClick}
                        isClickableLogo={isClickableLogo}
                        logoSvgId={logoSvgId}
                    />
                    <SafeAnchor
                        className={classNames(styles.login, loginClass)}
                        href={href}
                    >
                        {loginLabel}
                    </SafeAnchor>
                </>
            ) : (
                <>
                    <LogoAside
                        className={styles['mobile-hidden']}
                        handleClick={handleClick}
                        isClickableLogo={isClickableLogo}
                        logoSvgId={logoSvgId}
                    />
                    <div
                        className={classNames(styles['login-user'], {
                            [styles['login-user-open']]: displayUserDropdown
                        })}
                    >
                        <button
                            className={classNames([
                                styles.button,
                                styles['desktop-hidden']
                            ])}
                            onClick={toggleDropdown}
                        >
                            <Svg
                                className={classNames([
                                    styles['icon-chevron'],
                                    {
                                        [styles[
                                            'icon-rotate'
                                        ]]: displayUserDropdown
                                    }
                                ])}
                                svgId="icon-forward-chevron"
                            />
                            <Svg
                                className={styles['icon-user']}
                                svgId="icon-person"
                            />
                            {alertCount > 0 && <Indicator value={alertCount} />}
                            {firstName && (
                                <span
                                    className={classNames(
                                        styles.label,
                                        styles.condensed
                                    )}
                                >
                                    {firstName}
                                </span>
                            )}
                        </button>
                    </div>
                    <UserDropdownAside
                        displayUserDropdown={displayUserDropdown}
                        dropdownItems={userDropdownMenu}
                    />
                </>
            )}
        </div>
    );
};

export default LoginToolbar;
