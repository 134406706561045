import { useEffect, useState } from 'react';

export type WebWorker = new () => Worker;
export type WebWorkerSuccessHandler<T> = (ev: MessageEvent<T>) => any;

// The worker cache.
const workerCache = new Map<string, Worker>();

/**
 * Initializes a unique Web Worker instance, only once per given `name`.
 * If called again with the same `name`, the cached instance will be returned.
 * Automatically runs an `onmessageerror` handler.
 * @param name The name for the instantiated Web Worker used to store in the Map cache.
 * @param worker The Web Worker constructor.
 * @param successHandler A function to call when the Web Worker instance responds with a
 *      new message - runs for "message" events
 * @example
 *      const worker = useWebWorker('fancy-worker', MyWebWorker, successHandler);
 *      worker?.postMessage('any value to pass to the Web Worker script');
 */
export const useWebWorker = <T>(
    name: string,
    worker: WebWorker,
    successHandler?: WebWorkerSuccessHandler<T>
) => {
    const [cachedWorker, updateWorker] = useState<Worker | undefined>(
        undefined
    );
    const cacheNewWorker = () => {
        // Feature detect `window.Worker`
        const webWorker = window.Worker && new worker();
        workerCache.set(name, webWorker);

        // Update the worker.
        updateWorker(workerCache.get(name));
    };
    const setWorkerEvents = () => {
        const webWorker = workerCache.get(name);

        if (webWorker) {
            // Dispatch updated data.
            if (successHandler) {
                webWorker.addEventListener('message', successHandler);
            }

            // Handle the error.
            webWorker.onmessageerror = ({ data }) =>
                console.error('Worker error occurred', webWorker, data.data);
        }

        // Update the worker.
        updateWorker(workerCache.get(name));
    };

    useEffect(() => {
        if (!workerCache.has(name)) {
            cacheNewWorker();
        } else {
            setWorkerEvents();
        }

        // Cleanup
        return () => {
            if (successHandler) {
                // Cleanup the message event.
                workerCache
                    .get(name)
                    ?.removeEventListener('message', successHandler);
            }
        };
    }, [cachedWorker]);

    return cachedWorker;
};
