import { MetaTagProps } from '@tb-core/adapters/contentful/graphql/webpage-adapter';

interface MetaTagAttributes {
    content: string;
    name?: string;
    property?: string;
}

export const metaAttributeAdapter = (
    props: MetaTagProps
): MetaTagAttributes => ({
    content: props.description,
    ...(props.name && { name: props.name }),
    ...(props.property && { property: props.property })
});
