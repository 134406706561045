import { webFixedVersion } from '@tb-core/helpers/next-env';
import { Metadata } from '@tb-core/types/data-dog';

export const dataDogJSON = (
    type: string,
    source: string,
    errorMessage: string,
    metadataObj: Metadata
) => {
    const { userUid } = JSON.parse(sessionStorage.user);

    return {
        errorMessage,
        metadata: {
            clientDeviceType: 'Web',
            timestamp: new Date().toISOString(),
            userEmail: userUid || 'N/A',
            version: webFixedVersion || 'N/A',
            ...metadataObj
        },
        source,
        type
    };
};
