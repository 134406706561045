import { useContext } from 'react';

import { MenuContext } from '@tb-core/components/context/menu';

/**
 * Main use is inside `useCartSummary()` -> `cart-validation.worker.js` \
 *  to get product data out of menu: \
 *   `menu.menuProductCategory`
 *
 * Secondary use is to tell if it is happy hour... which seems strange right \
 * to need the entire menu in some cases just to determine if it is happy hour \
 * and/or the happy hour banner message: \
 *   `menu.happierHours` \
 *   `menu.storeSchedule`
 */
const useMenuContext = () => {
    const ctx = useContext(MenuContext);

    if (ctx === undefined) {
        throw new Error('useMenuContext must be used within a MenuProvider');
    }

    return ctx;
};

export default useMenuContext;
