import { useContext } from 'react';

// @TODO Update to core - current core page context provider will need to be deprecated.
import { ClientSideCartContext } from '@tb-core/components/context/products/client-side-cart';

export const useClientSideCartContext = () => {
    const ctx = useContext(ClientSideCartContext);

    if (ctx === undefined) {
        throw new Error(
            'useClientSideCartContext must be used within a ClientSideCartProvider'
        );
    }

    return ctx;
};
