import React from 'react';

import LazyLoadSvg from '@tb-core/components/composites/lazyload/svg';
import { Props } from '@tb-core/types';

import styles from './styles.module.scss';

const Input = ({
    ariaLabel,
    autocomplete = 'off',
    name,
    onblur,
    onfocus,
    onkeydown,
    onkeyup,
    oninput,
    placeholder,
    value
}: Props) => (
    <div className={styles['input-group']}>
        <div className={styles['input-control']}>
            <div className={styles['input-svg']}>
                <LazyLoadSvg svgId="icon-search" />
            </div>
            <input
                aria-label={ariaLabel}
                autoComplete={autocomplete}
                name={name}
                onBlur={onblur}
                onChange={oninput}
                onFocus={onfocus}
                onKeyDown={onkeydown}
                onKeyUp={onkeyup}
                id={name}
                placeholder={placeholder}
                required
                type="text"
                value={value}
            />
        </div>
    </div>
);

export default Input;
