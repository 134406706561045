import { useEffect, useState } from 'react';

import {
    deleteProductFromCart,
    getProductSubtotal
} from '@tb-core/helpers/products/cart';
import { LocalCart } from '@tb-core/hooks/client-side-cart/use-cart';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import usePageContext from '@tb-core/hooks/use-page-context';
import { CSCItem, CSCItemTemplate } from '@tb-core/types/client-side-cart';
import { Product } from '@tb-core/types/products';

/**
 * Fetches user's cart subtotal
 * @param cartLineItems cart items from cscItemAdapter
 * @param local the current local cart in storage
 * @param products products from useCartSummary
 * @param pdpProductsJson array of products from useProducts hook
 */
export const useCartSubTotal = (
    cartLineItems: CSCItemTemplate[],
    local: LocalCart,
    products: CSCItem[],
    pdpProductsJson: Product[]
): number => {
    const [subTotal, setSubTotal] = useState<number>(0);
    const [itemWasRemoved, setItemWasRemoved] = useState(false);

    const {
        content: { pageData }
    } = usePageContext();
    const { updateModals } = useLayoutActivityContext();

    const { itemUnavailableError } = pageData?.topicMeta?.generic || {};
    const total = getProductSubtotal(cartLineItems, true);
    const pdpProductsJsonHasUnavailableItems = pdpProductsJson.some(element => {
        return Object.keys(element).length === 0;
    });

    useEffect(() => {
        if (
            pdpProductsJsonHasUnavailableItems &&
            pdpProductsJson.length > 0 &&
            products.length > 0
        ) {
            const productWasDeleted = deleteProductFromCart(
                products,
                pdpProductsJson,
                local
            );

            if (productWasDeleted !== null) {
                setItemWasRemoved(true);
            }
        }
    }, [pdpProductsJson.length]);

    useEffect(() => {
        if (itemWasRemoved) {
            updateModals({
                ['error-modal']: {
                    description:
                        itemUnavailableError ||
                        'One or more items have been removed from your Cart due to store availability'
                }
            });
        }
    }, [itemWasRemoved]);

    useEffect(() => {
        if (total) {
            setSubTotal(total);
        }
    }, [total]);

    return subTotal;
};
