import { getDecodedCookie } from '@tb-core/helpers/cookies';
import { interpolate } from '@tb-core/helpers/interpolate';
import { webOrigin } from '@tb-core/helpers/next-env';
import { yumOidcLogoutUrl } from '@tb-core/next/api/urls';
import { postDeleteAuthCookies } from '@tb-core/providers/post-delete-auth-cookies';

// Send user to yum logout page to clean up cookies. Will get redirected back to Taco Bell after it is complete.
export const makeLogoutRequest = async () => {
    const idToken = getDecodedCookie('id_token');

    if (idToken) {
        window.sessionStorage.removeItem('token');
        await postDeleteAuthCookies();
        window.location.href = interpolate(webOrigin + yumOidcLogoutUrl, {
            idToken,
            redirectUri: webOrigin + '/?logout'
        });
        return;
    }

    window.location.href = webOrigin + '/?logout';
};
