import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import { ShowAside } from '@tb-core/components/composites/mini-toolbar';
import Svg from '@tb-core/components/simple/svg';

import fullToolbar from '@tb-core/components/composites/full-toolbar/full-toolbar.module.scss';

interface MenuIconProp {
    activateLoyalty: ShowAside;
    activateMenu: ShowAside;
    title: string;
}

const MiniMenuIcon = ({
    activateLoyalty,
    activateMenu,
    title
}: MenuIconProp) => (
    <SafeAnchorWithGaEvent
        aria-label="Taco Bell Site Menu"
        gaDataLayerConfig={{
            'Analytics-Action': 'Menu Nav',
            'Analytics-Value': 'Open Menu'
        }}
        href=""
        onClick={e => {
            e.preventDefault();
            activateLoyalty(false);
            activateMenu(true);
        }}
        role="button"
    >
        <Svg className={fullToolbar.menu} svgId="icon-menu" title={title} />
    </SafeAnchorWithGaEvent>
);

export default MiniMenuIcon;
