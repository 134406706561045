import { NumberInputOnChange } from '@tb-core/components/behavior/form-controls/number-input';
import Currency from '@tb-core/components/simple/currency';
import TextButton from '@tb-core/components/styled/buttons/text-button';
import StandardNumberInput from '@tb-core/components/styled/form-controls/standard-number-input';
import { interpolate } from '@tb-core/helpers/interpolate';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import useTooltipContext from '@tb-core/hooks/use-tooltip-context';
import {
    CSCItem,
    CSCItemModifier,
    CSCItemTemplate
} from '@tb-core/types/client-side-cart';

import styles from '../styles.module.scss';

interface MiniCartLineItemProps {
    item: CSCItemTemplate;
    itemWasRemovedLabel: string;
    modifiedLabel: string;
    removeLabel: string;
    updateCart?: (cartItem: CSCItem) => void;
}

// Copy to display in Confirm Modal
const ConfirmRemoveItemPrompt = ({ prompt = '' }: { prompt?: string }) => (
    <span dangerouslySetInnerHTML={{ __html: prompt }} />
);

const MiniCartLineItem = ({
    item,
    itemWasRemovedLabel,
    modifiedLabel,
    removeLabel,
    updateCart
}: MiniCartLineItemProps) => {
    const { updateModals } = useLayoutActivityContext();
    const { setMiniCartHeaderText } = useTooltipContext();

    const onUpdateQuantity: NumberInputOnChange = (newQty?: number) => {
        if (newQty && updateCart) {
            updateCart({
                ...item,
                qty: newQty
            });
        }
    };

    const onDeleteItem = () => {
        const miniCartHeaderText = interpolate(itemWasRemovedLabel, {
            product: item?.itemName
        });

        if (item && updateCart) {
            setMiniCartHeaderText(miniCartHeaderText);
            updateCart({
                ...item,
                qty: 0
            });
        }
    };

    const confirmRemoveHandler = () => {
        const confirmRemovePrompt = `Remove <strong>${item?.itemName}</strong> from your bag?`;

        updateModals({
            ['confirm-modal']: {
                callback: onDeleteItem,
                close: true,
                description: (
                    <ConfirmRemoveItemPrompt prompt={confirmRemovePrompt} />
                ),
                textCancel: 'No',
                textConfirm: 'Yes'
            }
        });
    };

    const renderComboItems = (comboItems: CSCItem[]) => (
        <ul className={styles['combo-list-items']}>
            {comboItems.map((item, i) => (
                <li key={`${item?.itemName}-${i}`}>
                    {item?.itemName}

                    {item?.modifiers && item.modifiers.length > 0 && (
                        <>
                            <p className={styles['modified-text']}>
                                {modifiedLabel}
                            </p>

                            {renderModifiersList(item.modifiers)}
                        </>
                    )}
                </li>
            ))}
        </ul>
    );

    const renderModifiers = (item: CSCItem) => (
        <>
            <p className={styles['modified-text']}>{modifiedLabel}</p>

            {item?.modifiers && renderModifiersList(item.modifiers)}
        </>
    );

    const renderModifiersList = (modifiers: CSCItemModifier[]) => (
        <ul className={styles['modifier-list-items']}>
            {modifiers &&
                modifiers.map((modifier, i) => (
                    <li key={`${modifier?.name}-${i}`}>{modifier.name}</li>
                ))}
        </ul>
    );

    return (
        <li className={styles['mini-cart-list-item']}>
            <div className={styles['mini-cart-content']}>
                <img
                    className={styles.thumbnail}
                    src={item?.image}
                    alt={item.itemName}
                />
                <StandardNumberInput
                    aria-label="Mini Cart Item Quantity"
                    className={styles.quantity}
                    max={50}
                    min={1}
                    onChange={onUpdateQuantity}
                    value={item.qty}
                />
                <div>
                    <p className={styles['product-name']}>{item.itemName}</p>

                    {item?.items?.length > 0 && renderComboItems(item.items)}

                    {item?.modifiers?.length > 0 && renderModifiers(item)}

                    <TextButton onClick={confirmRemoveHandler}>
                        {removeLabel}
                    </TextButton>
                </div>
            </div>

            <Currency
                className={styles.currency}
                qty={item.qty}
                total={item.price}
            />
        </li>
    );
};

export default MiniCartLineItem;
