export interface HappierHours {
    // Note: The Date part of these DateTimes should be ignored.
    //       In other words the Date will be some past date, not todays date.
    endTime: string; //      "Wednesday, 27 October 2021 17:00:00 +0530"
    endTimeUTC: string; //   "1635334200000"
    startTime: string; //    "Wednesday, 27 October 2021 11:00:00 +0530"
    startTimeUTC: string; // "1635312600000"
}
/**
 * Using menu.happierHours to determine if it is Happy Hour.
 */
export const isHappierHour = (happierHoursInfo?: HappierHours) => {
    if (!happierHoursInfo) {
        return false;
    }
    const startMiliseconds = parseInt(happierHoursInfo.startTimeUTC, 10);
    const endMiliseconds = parseInt(happierHoursInfo.endTimeUTC, 10);
    const startDateTime = new Date(startMiliseconds);
    const endDateTime = new Date(endMiliseconds);
    const todayDateTime = new Date();
    const todayStartDateTime = new Date(
        todayDateTime.toLocaleDateString() +
            ' ' +
            startDateTime.toLocaleTimeString()
    );
    const todayEndDateTime = new Date(
        todayDateTime.toLocaleDateString() +
            ' ' +
            endDateTime.toLocaleTimeString()
    );
    const happyHourStart = todayStartDateTime.getTime();
    const happyHourEnd = todayEndDateTime.getTime();
    const now = todayDateTime.getTime();
    const isAfterStart = happyHourStart < now;
    const isBeforeEnd = happyHourEnd > now;
    if (happyHourStart < happyHourEnd) {
        // This is for typical case when happy hour starts and ends today:
        return isAfterStart && isBeforeEnd;
    } else {
        // This is for edge case when happy hour overlaps end of day example:
        //  todayStartDateTime: Wed Dec 15 2021 22:30:00 GMT-0800
        //  todayEndDateTime: Wed Dec 15 2021 04:30:00 GMT-0800
        return isAfterStart || isBeforeEnd;
    }
};
