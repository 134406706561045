import classNames from 'classnames';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import LazyLoadSvg from '@tb-core/components/composites/lazyload/svg';

import styles from './styles.module.scss';

export interface LogoAside {
    className?: string;
    handleClick: () => void;
    isClickableLogo: boolean;
    logoSvgId: string;
}

const LogoAside = ({
    className,
    handleClick,
    isClickableLogo = true,
    logoSvgId = ''
}: LogoAside) =>
    isClickableLogo ? (
        <SafeAnchorWithGaEvent
            aria-label="Taco Bell Home"
            className={classNames([className, styles['logo-ta-co']])}
            gaDataLayerConfig={{
                'Analytics-Action': 'Left Nav',
                'Analytics-Value': 'Home'
            }}
            href="/"
            onClick={handleClick}
        >
            <LazyLoadSvg
                className={styles.svg}
                height="100%"
                svgId={logoSvgId}
            />
        </SafeAnchorWithGaEvent>
    ) : (
        // on checkout page only: logo is static ( not a clickable link)
        <div className={classNames(className, styles['logo-ta-co'])}>
            <LazyLoadSvg
                className={styles.svg}
                height="100%"
                svgId={logoSvgId}
            />
        </div>
    );

export default LogoAside;
