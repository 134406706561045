import classNames from 'classnames';
import React from 'react';

import LoadingSpinner from '@tb-core/components/composites/loading-spinner';
import ThemedColor from '@tb-core/components/themed/color';

export interface BrandedLoadingSpinnerProps {
    className?: string;
}

const BrandedLoadingSpinner = ({ className }: BrandedLoadingSpinnerProps) => (
    <ThemedColor theme={'brand'}>
        {({ color }) => (
            <LoadingSpinner className={classNames(color, className)} />
        )}
    </ThemedColor>
);

export default BrandedLoadingSpinner;
