import { ReactElement, useEffect, useState } from 'react';

import ListUtil from '@tb-core/components/util/list';
import { filterPurchaseableProducts } from '@tb-core/helpers/products/filter-purchasable-products';
import useStoreContext from '@tb-core/hooks/use-store-context';
import getMenu from '@tb-core/providers/get-menu';
import { Props, RealObject } from '@tb-core/types';
import { Product } from '@tb-core/types/products';

interface SearchProps {
    children: ((results: JSX.Element | null) => JSX.Element) | RealObject;
    delay?: number;
    Item: (props: Props) => ReactElement;
    onValueClick: (itemProps: Props, query: string) => void;
    onValueFocus?: (itemProps: Props) => void;
    pressedKey: string;
    query: string;
    queryMin?: number;
}

interface ProductContent {
    products: Product[];
}

const SearchUtil = ({
    children,
    delay = 300,
    Item,
    onValueClick,
    onValueFocus,
    pressedKey,
    query,
    queryMin = 3
}: SearchProps) => {
    const { storeId } = useStoreContext();

    const amountToDisplay = 5;
    const [focusedIndex, setFocusedIndex] = useState<number>(-1);
    const [suggestions, setSuggestions] = useState<Product[]>([]);
    const [menuProductsState, setMenuProductsState] = useState<
        ProductContent[]
    >([]);

    const limitIsMet: boolean = query.length >= queryMin;
    let timeout: number;

    const clearTimeout = () => {
        window.clearTimeout(timeout);
    };

    const closeList = () => {
        if (suggestions.length) {
            setSuggestions([]);
        }
    };

    const focusValue = (pressedKey: string) => {
        const next: Product = keyActionMap[pressedKey.toLowerCase()](
            suggestions
        );

        if (onValueFocus && next) {
            onValueFocus(next);
        }
    };

    const getSuggestions = async (query: string) => {
        let menuProducts: ProductContent[];

        // Call menu api only if menuProductsState state is empty,
        // otherwise use menuProductsState state to avoid calling api multiple times
        if (!menuProductsState?.length) {
            const { menuProductCategories } = await getMenu(storeId);

            setMenuProductsState(menuProductCategories as ProductContent[]);
            menuProducts = menuProductCategories as ProductContent[];
        } else {
            menuProducts = menuProductsState;
        }

        const filterMenuWithPurchaseableItems = filterPurchaseableProducts(
            menuProducts
        );

        // Removed duplicate products with the same name
        // then filters products based on the input search value
        const uniqueMenuItemSuggestions = [
            ...new Map(
                filterMenuWithPurchaseableItems.map((item: Product) => [
                    item.name,
                    item
                ])
            ).values()
        ]
            .filter((item: Product) =>
                item?.name.toLowerCase().includes(query.toLowerCase())
            )
            .slice(0, amountToDisplay);

        // Reset the focusedIndex since we have a new list now.
        setFocusedIndex(-1);

        // Update parsed suggestions.
        setSuggestions(uniqueMenuItemSuggestions);
    };

    const keyActionMap: RealObject = {
        arrowdown: () => {
            if (focusedIndex >= suggestions.length) {
                setFocusedIndex(-1);
                return {};
            }

            setFocusedIndex(prevIndex => prevIndex + 1);

            return suggestions[focusedIndex];
        },
        arrowup: () => {
            if (focusedIndex < 0) {
                setFocusedIndex(suggestions.length - 1);
                return {};
            }

            setFocusedIndex(prevIndex => prevIndex - 1);

            return suggestions[focusedIndex];
        },
        enter: () => onValueClick(suggestions[focusedIndex], query)
    };

    const nextAction = (action: () => void) => {
        action();
    };

    const throttleNextAction = (action: () => void, delay: number = 0) => {
        timeout = window.setTimeout(action, delay);
    };

    useEffect(() => {
        if (limitIsMet) {
            // Get new suggestions.
            throttleNextAction(() => getSuggestions(query), delay);
        }

        return () => clearTimeout();
    }, [query]);

    useEffect(() => {
        const keyLowerCased: string = pressedKey.toLowerCase();

        if (!suggestions.length && keyLowerCased === 'enter') {
            nextAction(() => focusValue(pressedKey));
        } else if (!!suggestions.length && !limitIsMet) {
            // Close the suggestion box.
            nextAction(() => closeList());
        } else if (
            !!suggestions.length &&
            Object.keys(keyActionMap).indexOf(keyLowerCased) > -1
        ) {
            // Focus a value from the suggestion list.
            nextAction(() => focusValue(pressedKey));
        }
    }, [keyActionMap]);

    return typeof children !== 'function'
        ? { children }
        : children(
              !!suggestions?.length ? (
                  <>
                      <ListUtil
                          list={suggestions}
                          listItem={(props: Props) => <Item {...props} />}
                      />
                  </>
              ) : null
          );
};

export default SearchUtil;
