import { createContext, ReactNode, useEffect, useState } from 'react';

import { cscItemAdapter } from '@tb-core/adapters/products/csc-item';
import { addAllProductCodes } from '@tb-core/helpers/product';
import { isHappierHour } from '@tb-core/helpers/products/is-happier-hour';
import { useCartSubTotal } from '@tb-core/hooks/client-side-cart/use-cart-subtotal';
import { useCartSummary } from '@tb-core/hooks/client-side-cart/use-cart-summary';
import { useClientSideCartContext } from '@tb-core/hooks/client-side-cart/use-client-side-cart-context';
import { useProducts } from '@tb-core/hooks/product/use-products';
import useMenuContext from '@tb-core/hooks/use-menu-context';
import { CSCItemTemplate } from '@tb-core/types/client-side-cart';

interface CartIconProviderProps {
    children: ReactNode;
}

interface CartIconProviderWrapperProps {
    children: JSX.Element;
    condition: boolean;
}

interface CartIconContextProps {
    cartLineItems: CSCItemTemplate[];
    subTotal: number;
}

const CartIconContext = createContext<CartIconContextProps>({
    cartLineItems: [],
    subTotal: 0
});
const { Consumer, Provider } = CartIconContext;

const CartIconProvider = ({ children }: CartIconProviderProps) => {
    const [subTotal, setSubTotal] = useState<number>(0);
    const { local } = useClientSideCartContext();
    const { happierHours } = useMenuContext();
    const isHH = isHappierHour(happierHours);
    const { products } = useCartSummary(local.cart, local.isCartInitialized);
    const productCodes = addAllProductCodes(products);
    const pdpProductsJson = useProducts(productCodes);
    const { cartLineItems } = cscItemAdapter({
        isHH,
        onTheSideLabel: '',
        pdpProductsJson,
        products
    });
    const total = useCartSubTotal(
        cartLineItems,
        local,
        products,
        pdpProductsJson
    );

    useEffect(() => {
        if (total) {
            setSubTotal(total);
        }
    }, [total]);

    return <Provider value={{ cartLineItems, subTotal }}>{children}</Provider>;
};

/**
 * Conditionally render the CartIconProvider. There are times when the cart icon
 * will not be toggled on, so this is useful to only render the provider when necessary.
 */
const CartIconProviderWrapper = ({
    condition,
    children
}: CartIconProviderWrapperProps) => {
    return condition ? (
        <CartIconProvider>{children}</CartIconProvider>
    ) : (
        <>{children}</>
    );
};

const CartIconConsumer = Consumer;

export default CartIconProviderWrapper;

export { CartIconConsumer, CartIconContext };
