import { useEffect, useState } from 'react';

import getLambdaSessionToken from '@tb-core/helpers/get-lambda-session-token';
import postGeocode from '@tb-core/providers/post-geocode';
import { GeocodeResponseProps } from '@tb-core/types/location';

export const useLatLong: () => [any, (query: string) => void] = () => {
    const [query, setQuery] = useState('');
    const [locations, setLocations] = useState<GeocodeResponseProps[]>([]);

    useEffect(() => {
        if (query.length >= 3) {
            // Need await inside of an async function
            async function setLocation() {
                const locations: GeocodeResponseProps[] = await postGeocode({
                    query,
                    sessiontoken: getLambdaSessionToken()
                });
                setLocations(locations);
            }
            setLocation();
        } else {
            setLocations([]);
        }
    }, [query]);

    return [locations, setQuery];
};
