import { useContext } from 'react';

import { CartTooltipContext } from '@tb-core/components/context/cart-tooltip';

const useCartTooltipContext = () => {
    const ctx = useContext(CartTooltipContext);

    if (ctx === undefined) {
        throw new Error(
            'useCartTooltipContext must be used within a CartTooltipProvider'
        );
    }

    return ctx;
};

export default useCartTooltipContext;
