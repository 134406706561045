import { useEffect, useState } from 'react';

import sessionStorage from '@tb-core/helpers/storage';
import {
    useWebWorker,
    WebWorkerSuccessHandler
} from '@tb-core/hooks/use-web-worker';
import {
    CSCItem,
    CSCItemDetail,
    DeleteCartItem
} from '@tb-core/types/client-side-cart';
import IndexedDBWorker from '@tb-core/workers/indexeddb.worker.js';

export enum CSCCartDbStore {
    Key = 'id',
    Name = 'cart'
}

// @TODO Move type to an IndexedDB Worker Hook.
export interface IndexedDBWebWorkerMessage<T = any> {
    data: T[];
    message: string | 'OK';
    status: 0 | 1;
}

export interface LocalCart {
    addCartItem?: (cartItem: CSCItem) => void;
    cart: CSCItem[];
    deleteAllCartItems: () => void;
    deleteCartItem?: (cartItem: DeleteCartItem) => void;
    getCartItem?: (cartItemPlu: string) => CSCItem | undefined;
    getCartItemsByPlu: (cartItemPlu: string) => CSCItem[];
    isCartInitialized: boolean;
    isDirty?: boolean;
    setDirty?: (isDirty: boolean) => void;
    updateCart?: (cartItem: CSCItem) => void;
}

export type UseLocalCartHook = () => LocalCart;

/**
 * Returns the current cart, as well as an API to interact with the cart.
 * @example
 *      const { addCartItem, cart, getCartItem, updateCart } = useLocalCart();
 *      const onClick = () => {
 *          const cartItem = getCartItem(id);
 *
 *          if (cartItem) {
 *              // Updates cart item for any quantity.
 *              updateCart({
 *                  ...cartItem,
 *                  qty: cartItem.qty + 1
 *              });
 *
 *              // Increases quantity.
 *              addCartItem(cartItem);
 *          }
 *      };
 *
 *      console.log('cart', cart); // => [{ name, plu, qty }, ...restItems]
 */
export const useLocalCart: UseLocalCartHook = () => {
    const [cart, setCart] = useState<string>();
    const cartItems: CSCItem[] = cart ? JSON.parse(cart) : [];
    const [isDirty, setDirty] = useState<boolean>();
    const onMessage: WebWorkerSuccessHandler<IndexedDBWebWorkerMessage<
        CSCItem
    >> = ({ data }) => setCart(JSON.stringify(data.data));
    const dbWorker = useWebWorker(
        `${CSCCartDbStore.Name}-indexeddb`,
        IndexedDBWorker,
        onMessage
    );
    // Internal API
    // Get's the whole cart.
    const getCart = () =>
        dbWorker?.postMessage({
            action: 'getAll',
            storeName: CSCCartDbStore.Name
        });
    // External API
    const addCartItem = (cartItem: CSCItem) => {
        const record = getCartItem(cartItem.id as string);

        cartItem.qty = record?.qty
            ? record.qty + (cartItem?.qty || 0)
            : cartItem.qty;
        updateCart(cartItem);
    };
    const deleteAllCartItems = () => {
        setDirty(true);
        dbWorker?.postMessage({
            action: 'deleteAll',
            storeName: CSCCartDbStore.Name
        });
    };
    const deleteCartItem = ({ id, plu }: DeleteCartItem) => {
        const productCode = id || plu;
        setDirty(true);

        try {
            dbWorker?.postMessage({
                action: 'delete',
                id: productCode,
                storeName: CSCCartDbStore.Name
            });
        } catch (error) {
            console.error('Failed to remove cart item.', id, plu);
        }
    };

    const getCartItem = (cartItemId: string) =>
        cartItems.find(({ id }) => id === cartItemId);

    const getCartItemsByPlu = (plu: string) =>
        cartItems.filter(item => item.plu === plu);

    const updateCart = (currentCartItem: CSCItemDetail | CSCItem) => {
        setDirty(true);

        const cartItem = {
            ...(cartItems.find(({ id }) => id === currentCartItem.id) || {}),
            ...currentCartItem
        };

        if ((cartItem.qty as number) <= 0) {
            deleteCartItem({ id: cartItem.id, plu: cartItem.plu });
        } else {
            // Update the IndexedDB.
            dbWorker?.postMessage({
                action: 'put',
                data: cartItem,
                storeKey: CSCCartDbStore.Key,
                storeName: CSCCartDbStore.Name
            });
        }
    };

    useEffect(() => {
        if (typeof cart !== 'undefined') {
            setDirty(cartItems.length > 0);
        }
    }, [cart, cartItems]);

    // "Set dirty flag in browser session" effect
    useEffect(() => {
        if (isDirty !== undefined) {
            sessionStorage.setItems({ cartIsDirty: isDirty });
        }
    }, [isDirty]);

    // "IndexedDB Worker defined" effect
    useEffect(() => {
        // Initialize the cart (only once per session.)
        if (dbWorker) {
            getCart();
        }
    }, [dbWorker]);

    return {
        addCartItem,
        cart: cartItems,
        deleteAllCartItems,
        deleteCartItem,
        getCartItem,
        getCartItemsByPlu,
        // If `cart` is undefined, the cart hasn't been initialized.
        isCartInitialized: !!cart,
        isDirty,
        setDirty,
        updateCart
    };
};
