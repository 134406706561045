import { Store } from '@tb-core/types';

export const parseSelectedStoreAddress = ({
    storeAddress,
    storeCity,
    storePinCode,
    storeState
}: Store) =>
    `${storeAddress}${storeCity ? ', ' + storeCity : ''}${
        storeState ? ', ' + storeState : ''
    } ${storePinCode}`.trim();
