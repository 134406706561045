import LazyLoadImage from '@tb-core/components/composites/lazyload/image';
import withNextConfig from '@tb-core/components/hoc/with-next-config';
import { ImageProps } from '@tb-core/components/styled/image';
import { s3AssetPath } from '@tb-core/helpers/contentful/s3-asset-path';
import { assetPath } from '@tb-core/helpers/next-env';

import styles from './styles.module.scss';

export interface ResponsiveImageProps extends ImageProps {
    srcset?: string | null;
}

// @TODO move to new component structure
const ResponsiveImage = ({
    srcset = null,
    ...imageProps
}: ResponsiveImageProps) =>
    srcset ? (
        <picture className={styles.picture}>
            <source
                media="(max-width: 640px)"
                srcSet={
                    srcset.startsWith('http')
                        ? srcset
                        : assetPath + s3AssetPath('/_static/images/', srcset)
                }
            />
            <LazyLoadImage {...imageProps} />
        </picture>
    ) : (
        <LazyLoadImage {...imageProps} />
    );

export default withNextConfig(ResponsiveImage);
