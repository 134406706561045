import LazyLoad from 'react-lazyload';

import Image, { ImageProps } from '@tb-core/components/styled/image';

export interface LazyLoadImageProps extends ImageProps {
    height?: string;
}

const LazyLoadImage = ({
    height = '100%',
    src = null,
    ...props
}: LazyLoadImageProps) => (
    <LazyLoad height={height} scrollContainer={'#scrollContainer'}>
        <Image {...props} src={src} />
    </LazyLoad>
);

export default LazyLoadImage;
