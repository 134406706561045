import { track } from '@amplitude/analytics-browser';
import { useState } from 'react';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import UserDropdownMenu from '@tb-core/components/composites/full-toolbar/user-drop-down';
import Svg from '@tb-core/components/simple/svg';
import Indicator from '@tb-core/components/styled/indicator';
import BrandedLinkButton from '@tb-core/components/styled/links/branded-link-button';
import { gtmLinkClick } from '@tb-core/helpers/analytics/google';
import { getLoginTitle } from '@tb-core/helpers/toolbar';
import usePageContext from '@tb-core/hooks/use-page-context';
import useUserContext from '@tb-core/hooks/use-user-context';

import styles from './styles.module.scss';

const UserNameplate = ({
    alertCount,
    firstName,
    iconTitleUser
}: {
    alertCount: number;
    firstName?: string;
    iconTitleUser: string;
}) => (
    <>
        <Svg
            className={styles.icon}
            svgId="icon-person"
            title={iconTitleUser}
        />
        {alertCount > 0 && <Indicator value={alertCount} />}
        <span className={styles.label}>{firstName}</span>
    </>
);

const UserIcon = () => {
    const {
        content: {
            pageData: {
                seoTitle,
                slug,
                topicMeta: {
                    layout: {
                        iconTitleUser,
                        loggedInHref,
                        loggedOutHrefYum,
                        loggedOutLabel,
                        userDropdownMenu
                    }
                }
            }
        }
    } = usePageContext();

    const user = useUserContext();
    const { isLoggedIn } = user;
    const [dropdownMenuToggle, setDropdownToggle] = useState(false);
    const userNameplateProps = isLoggedIn
        ? {
              alertCount: user.offersCount + user.ordersCount,
              firstName: user.firstName,
              iconTitleUser
          }
        : { alertCount: 0, iconTitleUser };
    const gtmClickData = {
        'Analytics-Action': 'Top Nav',
        'Analytics-Value': getLoginTitle(isLoggedIn)
    };

    const amplitudeData = {
        page_path: slug,
        page_title: seoTitle
    };

    const onClickToggle = () => {
        setDropdownToggle(!dropdownMenuToggle);
        gtmLinkClick(gtmClickData);
    };

    const onClickLogin = () => {
        track('Clicked Login', amplitudeData);
    };

    const loginUrl = loggedOutHrefYum;

    return (
        <>
            <span className={styles['divider']}>|</span>
            {isLoggedIn ? (
                <>
                    <div className={styles['desktop']}>
                        <button
                            className={styles['user-button']}
                            onClick={onClickToggle}
                            onMouseEnter={() => setDropdownToggle(true)}
                        >
                            <UserNameplate {...userNameplateProps} />
                        </button>
                        <UserDropdownMenu
                            dropdownMenuToggle={dropdownMenuToggle}
                            toggleHover={() => setDropdownToggle(false)}
                            {...userDropdownMenu}
                        />
                    </div>

                    <div className={styles['mobile']}>
                        <SafeAnchorWithGaEvent
                            className={styles['user-button']}
                            gaDataLayerConfig={gtmClickData}
                            href={loggedInHref}
                        >
                            <UserNameplate {...userNameplateProps} />
                        </SafeAnchorWithGaEvent>
                    </div>
                </>
            ) : (
                <div>
                    <BrandedLinkButton
                        className={styles['login-button']}
                        href={loginUrl}
                        inverse={true}
                        onClick={onClickLogin}
                    >
                        {loggedOutLabel}
                    </BrandedLinkButton>
                </div>
            )}
        </>
    );
};

/**
 * @param isCompact - When true uses the new 'compact' look \
 * The 'compact' look will be used by the Cart / Checkout page \
 * All other pages use the \<StandardToolbar\> which has the 'original' look
 */
export const UserDisplay = ({ isCompact = false }: { isCompact?: boolean }) => {
    const { pageControls } = usePageContext().content.pageData.layout || {};
    const className = isCompact ? styles['compact'] : styles['original'];

    return (
        <>
            {pageControls?.indexOf('User') >= 0 && (
                <div className={className}>
                    <UserIcon />
                </div>
            )}
        </>
    );
};
