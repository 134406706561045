import { track } from '@amplitude/analytics-browser';
import 'isomorphic-unfetch';
import { ChangeEvent, Component, ReactElement } from 'react';

import SafeAnchor from '@tb-core/components/behavior/safe-anchor';
import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import { UserContext } from '@tb-core/components/context/user';
import Input from '@tb-core/components/styled/input';
import SearchUtil from '@tb-core/components/util/search';
import { gtmLinkClick } from '@tb-core/helpers/analytics/google';
import { interpolate } from '@tb-core/helpers/interpolate';
import { GetStoreData } from '@tb-core/helpers/storage';
import { Props, RealObject } from '@tb-core/types';
import { ProductImage } from '@tb-core/types/products';

import styles from './styles.module.scss';
interface ProductSearchState {
    key: string;
    query: string;
    value: string;
}
export default class ProductSearch extends Component<
    Props,
    ProductSearchState
> {
    static contextType = UserContext;

    private placeholder = 'SEARCH OUR FOOD MENU'; // todo move to contentful

    blurTimeout?: NodeJS.Timeout;
    state = {
        key: '',
        query: '',
        value: ''
    };
    blurEvent = () =>
        (this.blurTimeout = setTimeout(
            () => this.setState({ query: '', value: '' }),
            250
        ));
    focusItemEvent = () => this.blurTimeout && clearTimeout(this.blurTimeout);

    handleSearchListItemClick = () => {
        track('Searched Menu', { search_text: this.state.value });
    };

    shouldComponentUpdate(
        _props: Props,
        { key, query, value }: ProductSearchState
    ) {
        return (
            (this.state.key !== key && !!key) ||
            this.state.query !== query ||
            this.state.value !== value
        );
    }

    render() {
        const { placeholder } = this.props;
        const { key, query, value } = this.state;

        return (
            <div aria-live="polite" className={styles.search}>
                <SearchUtil
                    Item={this.renderItem}
                    query={query}
                    onValueFocus={({ name }: RealObject) =>
                        this.setState(({ query }: ProductSearchState) => ({
                            key: '',
                            value: name || query
                        }))
                    }
                    onValueClick={this.followLink}
                    pressedKey={key}
                >
                    {(results: ReactElement) => (
                        <>
                            <Input
                                ariaLabel={placeholder || this.placeholder}
                                onblur={this.blurEvent}
                                oninput={({
                                    target: { value }
                                }: ChangeEvent<HTMLInputElement>) =>
                                    this.setState({
                                        query: value,
                                        value
                                    })
                                }
                                onkeydown={({ key }: KeyboardEvent) =>
                                    this.setState(() => ({
                                        key
                                    }))
                                }
                                placeholder={placeholder || this.placeholder}
                                value={value}
                            />
                            {results && (
                                <div className={styles.results}>
                                    {results}
                                    <SafeAnchorWithGaEvent
                                        className={styles['see-all-results']}
                                        gaDataLayerConfig={{
                                            'Analytics-Action':
                                                'See All Results',
                                            'Analytics-Value':
                                                'Search>See All Results'
                                        }}
                                        href={`/search?text=${query}`}
                                        onBlur={this.blurEvent}
                                        onFocus={this.focusItemEvent}
                                    ></SafeAnchorWithGaEvent>
                                </div>
                            )}
                        </>
                    )}
                </SearchUtil>
            </div>
        );
    }

    private followLink(itemProps: Props) {
        gtmLinkClick({
            'Analytics-Action': 'Search',
            'Analytics-Value': 'Search>Dropdown'
        });

        if (itemProps && itemProps.url) {
            window.location.href = itemProps.url;
        }

        return itemProps;
    }

    private renderItem = ({ raw: { images, name, url } }: Props) => {
        const storeId = GetStoreData()?.storeId || '';
        const queryRe = new RegExp(this.state.query, 'gi');

        // Original matches - ensures capitalization is correct in the final name.
        const queryMatches = name.match(queryRe);
        // Check for preferred image dimension, but fallback to previous default if not found
        const defaultImage =
            (images as ProductImage[]).find(img => img.format === '269x269') ||
            images[2];

        return (
            <SafeAnchor
                className={styles.suggestion}
                href={interpolate(url, { storeId })}
                onBlur={this.blurEvent}
                onFocus={this.focusItemEvent}
                onClick={this.handleSearchListItemClick}
            >
                <>
                    <img
                        alt=""
                        height="50"
                        src={(defaultImage || {}).url}
                        width="50"
                    />

                    <span className={styles.name}>
                        {(name as string).split(queryRe).map((part, i) =>
                            queryMatches && queryMatches[i] ? (
                                <span key={i}>
                                    {part}
                                    <strong>{queryMatches[i]}</strong>
                                </span>
                            ) : (
                                part
                            )
                        )}
                    </span>
                </>
            </SafeAnchor>
        );
    };
}
