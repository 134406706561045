import { useState } from 'react';

import { RealObject } from '@tb-core/types';

export type StatusComponent = <P extends RealObject<JSX.Element>>(
    props: P
) => JSX.Element;

/**
 * Loads a component based on the status provided.
 * @param initialState The status to load.
 * @example
 *      const { Status, setStatus } = useStatus('loading');
 *
 *      return (
 *          <Status
 *              loading={<LoadingState />}
 *              empty={<EmptyState />}
 *              error={<ErrorState error={error} retry={fetchResults}/>}
 *              success={<Results results={results} />}
 *
 *              {* A fallback state - when no other state could be met *}
 *              default={}
 *          />
 *      );
 */
export const useStatus = <T extends string | number>(initialState: T) => {
    const [status, setStatus] = useState<T>(initialState);
    const Status: StatusComponent = <P extends RealObject<JSX.Element>>(
        props: P
    ) => props[status] || props.default || null;

    return [Status, setStatus, status];
};
