import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { InputValue } from '@tb-core/types/elements/form';

export type InputValueHook = (
    value: InputValue
) => [InputValue, Dispatch<SetStateAction<InputValue | undefined>>];

/**
 * State reactivity for an input's value.
 * @example
 *      import { useInputValue } from '@tb-core/hooks';
 *
 *      const [ currentValue, setValue ] = useInputValue({
 *          onChange: onChangeCustom,
 *          value: defaultValue
 *      });
 */

export const useInputValue: InputValueHook = (value = '') => {
    const [currentValue, setCurrentValue] = useState<InputValue>(value);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    return [currentValue, setCurrentValue];
};
