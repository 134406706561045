import classNames from 'classnames';
import { SyntheticEvent, useEffect } from 'react';

import BrandedLoadingSpinner from '@tb-core/components/composites/branded-loading-spinner';
import Center from '@tb-core/components/composites/center';
import Currency from '@tb-core/components/simple/currency';
import LazyIconClose from '@tb-core/components/simple/icons/lazy-icon-close';
import Button from '@tb-core/components/styled/buttons/button';
import BrandedLinkButton from '@tb-core/components/styled/links/branded-link-button';
import MiniCartLineItem from '@tb-core/components/styled/mini-cart/mini-cart-line-item';
import { KitchenMessages } from '@tb-core/constants/products';
import { LocalCart } from '@tb-core/hooks/client-side-cart/use-cart';
import usePageContext from '@tb-core/hooks/use-page-context';
import useTooltipContext from '@tb-core/hooks/use-tooltip-context';
import { CSCItemTemplate } from '@tb-core/types/client-side-cart';

import styles from './styles.module.scss';

interface MiniCartProps {
    cartLineItems: CSCItemTemplate[];
    cartQuantity: number;
    local: LocalCart;
    onClick?: (e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) => void;
    total: number;
}

const MiniCart = ({
    cartLineItems,
    cartQuantity,
    local,
    onClick,
    total
}: MiniCartProps) => {
    const {
        content: { pageData }
    } = usePageContext();
    const {
        calculatedCheckoutLabel = 'Calculated At Checkout',
        itemWasRemovedLabel = '{product} removed from your bag!',
        miniCartCtaLabel = 'View Bag & Checkout',
        modifiedLabel = 'MODIFIED',
        removeLabel = 'Remove',
        salesTaxLabel = 'Sales Tax',
        subtotalLabel = 'Subtotal'
    } = pageData?.topicMeta?.cart || {};
    const {
        miniCartHeaderText,
        setShowMiniCart,
        showMiniCart
    } = useTooltipContext();

    const onClickClose = () => {
        setShowMiniCart(false);
    };

    useEffect(() => {
        if (cartQuantity === 0) {
            setShowMiniCart(false);
        }
    }, [cartQuantity]);

    return (
        <article
            className={classNames({
                [styles['mini-cart']]: true,
                [styles['show-mini-cart']]: showMiniCart
            })}
        >
            <header>
                <h5>{miniCartHeaderText}</h5>
                <Button
                    aria-label="Close"
                    autoFocus={true}
                    className={styles.close}
                    onClick={onClickClose}
                    tabIndex={0}
                >
                    <LazyIconClose size="1em" />
                </Button>
            </header>

            <ul className={styles['mini-cart-items']}>
                {cartLineItems?.length > 0 && cartQuantity > 0 ? (
                    cartLineItems
                        // Do not display no utensils item in mini cart list
                        .filter(item => item.plu !== KitchenMessages.NOUTENSILS)
                        .map(item => (
                            <MiniCartLineItem
                                key={item?.id}
                                item={item}
                                itemWasRemovedLabel={itemWasRemovedLabel}
                                modifiedLabel={modifiedLabel}
                                removeLabel={removeLabel}
                                updateCart={local.updateCart}
                            />
                        ))
                ) : (
                    <Center className={styles['loading-spinner']}>
                        <BrandedLoadingSpinner />
                    </Center>
                )}
            </ul>

            <footer>
                <div className={styles['sub-total-container']}>
                    <div>
                        <span>{subtotalLabel}</span>
                        <small>{salesTaxLabel}</small>
                    </div>
                    <div>
                        <Currency total={total} />
                        <small>{calculatedCheckoutLabel}</small>
                    </div>
                </div>

                <BrandedLinkButton
                    className={styles['mini-cart-cta']}
                    href="/order/cart"
                    inverse={false}
                    onClick={onClick}
                >
                    {miniCartCtaLabel}
                </BrandedLinkButton>
            </footer>
        </article>
    );
};

export default MiniCart;
