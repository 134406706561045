import classNames from 'classnames';

import CartIcon from '@tb-core/components/composites/full-toolbar/cart-icon';
import LoyaltyIcon from '@tb-core/components/composites/full-toolbar/loyalty-icon';
import PickupStoreWithContext from '@tb-core/components/composites/pickup-store-with-context';
import ProductSearch from '@tb-core/components/container/product-search';
import { UserDisplay } from '@tb-core/components/styled/user-display';
import { PageControl } from '@tb-core/types';

import styles from './styles.module.scss';

export interface FullToolbarProps {
    activateLoyalty: (activate: boolean) => void;
    activeLoyalty: boolean;
    cartLabel?: string;
    iconTitleCart?: string;
    iconTitleRewards?: string;
    pageControls: PageControl[];
}

const FullToolbar = ({
    activateLoyalty,
    activeLoyalty,
    cartLabel,
    iconTitleCart = '',
    iconTitleRewards = '',
    pageControls,
    ...props
}: FullToolbarProps) => (
    <div {...props} className={classNames(styles.full, styles.toolbar)}>
        {/* Product Search */}
        {pageControls?.indexOf('Product Search') >= 0 && <ProductSearch />}
        <div className={styles['flex-section']}>
            {/* Pickup Store */}
            {pageControls?.indexOf('Selected Store') >= 0 && (
                <PickupStoreWithContext collapsed={true} isNav={true} />
            )}
            {/* Login / Profile/Account */}
            <UserDisplay />
            {/* Loyalty */}
            {pageControls?.indexOf('Loyalty') >= 0 && (
                <LoyaltyIcon
                    activateLoyalty={activateLoyalty}
                    activeLoyalty={activeLoyalty}
                    title={iconTitleRewards}
                />
            )}
            {/* Cart */}
            {pageControls?.indexOf('Cart') >= 0 && (
                <CartIcon cartLabel={cartLabel} title={iconTitleCart} />
            )}
        </div>
    </div>
);

export default FullToolbar;
