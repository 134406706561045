import classNames from 'classnames';

import CartIcon from '@tb-core/components/composites/full-toolbar/cart-icon';
import MiniLogoIcon from '@tb-core/components/composites/mini-toolbar/mini-logo-icon';
import MiniLoyaltyIcon from '@tb-core/components/composites/mini-toolbar/mini-loyalty-icon';
import MiniMenuIcon from '@tb-core/components/composites/mini-toolbar/mini-menu-icon';
import { UserDisplay } from '@tb-core/components/styled/user-display';
import { PageControl } from '@tb-core/types';

import styles from '@tb-core/components/composites/full-toolbar/styles.module.scss';
import miniToolbar from '@tb-core/components/composites/mini-toolbar/mini-toolbar.module.scss';

export type ShowAside = (activate: boolean) => void;

export interface MiniToolbarProps {
    activateLoyalty: (activate: boolean) => void;
    activateMenu: (activate: boolean) => void;
    activeLoyalty: boolean;
    iconTitleCart?: string;
    iconTitleLogo?: string;
    iconTitleMenu?: string;
    iconTitleRewards?: string;
    logo: string;
    pageControls: PageControl[];
}

const MiniToolbar = ({
    activateLoyalty,
    activateMenu,
    activeLoyalty,
    iconTitleCart = '',
    iconTitleLogo = '',
    iconTitleMenu = '',
    iconTitleRewards = '',
    logo,
    pageControls,
    ...props
}: MiniToolbarProps) => (
    <div {...props} className={classNames(styles.mini, styles.toolbar)}>
        <div className={miniToolbar['flex-group-1']}>
            <MiniMenuIcon
                activateLoyalty={activateLoyalty}
                activateMenu={activateMenu}
                title={iconTitleMenu}
            />
            {/* Login / Profile/Account */}
            <UserDisplay />
        </div>
        <div className={miniToolbar['flex-group-2']}>
            {/* Home */}
            {logo && (
                <MiniLogoIcon
                    isClickableLogo={true}
                    logo={logo}
                    title={iconTitleLogo}
                />
            )}
        </div>
        <div className={miniToolbar['flex-group-3']}>
            {/* Loyalty */}
            {pageControls?.indexOf('Loyalty') >= 0 && (
                <MiniLoyaltyIcon
                    activateLoyalty={activateLoyalty}
                    activeLoyalty={activeLoyalty}
                    title={iconTitleRewards}
                />
            )}
            {/* Cart */}
            {pageControls?.indexOf('Cart') >= 0 && (
                <CartIcon isFullToolbar={false} title={iconTitleCart} />
            )}
        </div>
    </div>
);
export default MiniToolbar;
