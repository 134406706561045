import { useContext } from 'react';

import { StoreContext } from '@tb-core/components/context/store';
import { Store } from '@tb-core/types';

const useStoreContext = () => {
    const ctx = useContext<Store | undefined>(StoreContext);

    if (ctx === undefined) {
        throw new Error('useStoreContext must be used within a StoreProvider');
    }

    return ctx;
};

export default useStoreContext;
