import { useState } from 'react';

interface UseTimerProps {
    timeout: number;
}

type UseTimerHook = (
    timerProps: UseTimerProps
) => [boolean, (dispatchProps?: DispatchCallBack) => () => void];

type DispatchCallBack = () => any;

export const useTimer: UseTimerHook = ({ timeout = 3000 }) => {
    const [ready, setReady] = useState(() => true);

    const dispatch = (dispatchCallBack?: DispatchCallBack) => {
        setReady(false);
        const timeOut = setTimeout(() => {
            // If callback is passed to dispatch function, call it after the timeout
            if (dispatchCallBack) {
                dispatchCallBack();
            }
            setReady(true);
        }, timeout);

        return () => {
            if (timeOut) {
                clearTimeout(timeOut);
            }
        };
    };

    return [ready, dispatch];
};
