import classNames from 'classnames';

import NumberInput, {
    NumberInputProps
} from '@tb-core/components/behavior/form-controls/number-input';

import styles from './styles.module.scss';

const StandardNumberInput = ({
    className,
    value = 0,
    ...props
}: NumberInputProps) => (
    <NumberInput
        {...props}
        className={classNames(styles['number-input'], className)}
        ref={undefined}
        value={value}
    />
);

export default StandardNumberInput;
