import classNames from 'classnames';
import { SyntheticEvent } from 'react';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import { LoyaltyIconProps } from '@tb-core/components/composites/full-toolbar/loyalty-icon';
import Svg from '@tb-core/components/simple/svg';

import fullToolbar from '@tb-core/components/composites/full-toolbar/full-toolbar.module.scss';

const MiniLoyaltyIcon = ({
    activateLoyalty,
    activeLoyalty
}: LoyaltyIconProps) => {
    const toggleLoyalty = (e: SyntheticEvent) => {
        e.preventDefault();
        activateLoyalty(!activeLoyalty);
    };
    return (
        <SafeAnchorWithGaEvent
            className={classNames(fullToolbar['icon-animate'], {
                [fullToolbar.animate]: activeLoyalty === true
            })}
            gaDataLayerConfig={{
                'Analytics-Action': 'Top Nav',
                'Analytics-Value': 'Rewards'
            }}
            href="#"
            onClick={toggleLoyalty}
        >
            <Svg
                className={fullToolbar.icon}
                svgId="icon-loyalty"
                title="Taco Bell Rewards"
            />
        </SafeAnchorWithGaEvent>
    );
};

export default MiniLoyaltyIcon;
