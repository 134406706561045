import classNames from 'classnames';
import { ReactNode } from 'react';

import StandardModal from '@tb-core/components/composites/standard-modal';
import BrandedButton from '@tb-core/components/styled/buttons/branded-button';
import Image from '@tb-core/components/styled/image';
import BrandedLinkButton from '@tb-core/components/styled/links/branded-link-button';
import { assetPath } from '@tb-core/helpers/next-env';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import { RealObject } from '@tb-core/types';

import styles from './styles.module.scss';

export const defaultErrorModalImage = (
    <Image
        alt="dead taco."
        className={styles['error-modal-image']}
        src={`${assetPath}/_static/web/images/contact-us/contact-us-error-dead-taco.png`}
        tabIndexProp={-1}
    />
);

export const hideImageErrorModal = (
    updateModals: (a: RealObject) => void, // required
    id: string = 'image-error-modal' // optional
) => {
    updateModals({ [id]: undefined });
};
interface ShowImageErrorModalProps {
    buttonLink?: string;
    buttonText: string;
    className?: string;
    id?: string;
    image?: ReactNode;
    message: string | ReactNode;
    title: string;
    updateModals: (a: RealObject) => void;
}
// Put this function call inside a UseEffect or click event handler, so that it is only called once:
export const showImageErrorModal = ({
    buttonLink, // if no link then just close modal on click.
    buttonText,
    className,
    id = 'image-error-modal',
    image,
    message,
    title,
    updateModals
}: ShowImageErrorModalProps) => {
    const hideModal = () => {
        hideImageErrorModal(updateModals, id);
    };

    updateModals({
        [id]: {
            className: classNames(styles.modal, className),
            footer: buttonLink ? (
                <BrandedLinkButton
                    className={styles['error-modal-button']}
                    href={buttonLink}
                >
                    {buttonText}
                </BrandedLinkButton>
            ) : (
                <BrandedButton
                    className={styles['error-modal-button']}
                    onClick={hideModal}
                    theme={'brand'}
                >
                    {buttonText}
                </BrandedButton>
            ),
            image: image || defaultErrorModalImage,
            message: (
                <div className={styles['error-modal-message']}>{message}</div>
            ),
            title: <h3 className={styles['error-modal-title']}>{title}</h3>
        }
    });
};

interface ImageErrorModalProps {
    id?: string;
}

const ImageErrorModal = ({
    id = 'image-error-modal'
}: ImageErrorModalProps) => {
    const { modals } = useLayoutActivityContext();
    const { className, message, footer, image, title } = modals[id] || {};

    return (
        <StandardModal
            body={
                <>
                    {image}
                    {title}
                    {message}
                </>
            }
            className={className}
            footer={footer}
            id={id}
            showHeader={false}
        />
    );
};

export default ImageErrorModal;
