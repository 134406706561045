import { createContext, ReactNode, useEffect, useMemo } from 'react';

import {
    LocalCart,
    useLocalCart
} from '@tb-core/hooks/client-side-cart/use-cart';
import {
    RemoteCart,
    useRemoteCart
} from '@tb-core/hooks/client-side-cart/use-remote-cart';

export interface ClientSideCartContextProps {
    local: LocalCart;
    remote: RemoteCart;
}

export interface ClientSideCartProviderProps {
    children: ReactNode;
    // `true` will allow local cart updates to sync/put to the remote cart.
    syncOnUpdate?: boolean;
}

// First we will make a new context.
const ClientSideCartContext = createContext<
    ClientSideCartContextProps | undefined
>(undefined);
const { Provider } = ClientSideCartContext;

const ClientSideCartProvider = ({
    children,
    syncOnUpdate = false
}: ClientSideCartProviderProps) => {
    const localCart = useLocalCart();
    const remoteCart = useRemoteCart(localCart, { syncOnUpdate });
    const ctx = useMemo(
        () => ({
            local: { ...localCart },
            remote: remoteCart
        }),
        [localCart.cart]
    );

    // "Remote cart response payload" effect.
    // Updates the local cart.
    useEffect(() => {
        if (localCart && localCart.cart) {
            if (!localCart.cart?.length && remoteCart.payload) {
                remoteCart.payload.items?.forEach(
                    cartItem =>
                        localCart.addCartItem && localCart.addCartItem(cartItem)
                );
            }
        }
    }, [localCart.cart, remoteCart.payload]);

    return <Provider value={ctx}>{children}</Provider>;
};

export default ClientSideCartProvider;
export { ClientSideCartContext };
