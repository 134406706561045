import { RealObject } from '@tb-core/types';

export function field(child: string, parent: RealObject) {
    return ((parent || {}).fields || {})[child];
}

export function fieldsOf(parent: RealObject) {
    return { ...(parent || {}).fields };
}

export function fieldsOfChild(child: string, parent: RealObject<RealObject>) {
    return fieldsOf(field(child, parent));
}
