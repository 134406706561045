import { useEffect, useState } from 'react';

import useStoreContext from '@tb-core/hooks/use-store-context';
import getProduct from '@tb-core/providers/get-product';
import { Product } from '@tb-core/types/products';

interface StoreProduct {
    product: Product;
    storeId: string;
}

/**
 * Fetches product data based on a given product id.
 * @param productCodes the product codes/plus
 * @param productCodesString a string representation of the product codes
 */
export const useProducts = (productCodes: string[]): Product[] => {
    const productCodesString = productCodes.join('');
    const [products, setProducts] = useState<StoreProduct[]>(
        [] as StoreProduct[]
    );
    const { storeId } = useStoreContext();

    const fetchProducts = async () => {
        const newProductCodes: string[] = [];
        productCodes.forEach(productId => {
            const productMatch = products.find(
                product =>
                    product.storeId === storeId &&
                    product.product.code === productId
            );
            if (!productMatch) {
                newProductCodes.push(productId);
            }
        });
        const promises = newProductCodes.map(async productId => {
            return await getProduct(productId, storeId);
        });

        Promise.all(promises).then((data: Product[]) => {
            setProducts(
                products.concat(data.map(x => ({ product: x, storeId })))
            );
        });
    };

    useEffect(() => {
        if (productCodesString.length > 0) {
            fetchProducts();
        }
    }, [productCodesString, storeId]);

    return products.filter(x => x.storeId === storeId).map(x => x.product);
};
