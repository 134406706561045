import { DAYPART_BREAKFAST } from '@tb-core/constants/dayparts';
import { isBreakfastAvailable } from '@tb-core/helpers/date';
import {
    getItemDetails,
    getModifierDetails,
    isProductValid
} from '@tb-core/helpers/product';
import { getProductPrice } from '@tb-core/helpers/products/cart';
import {
    CSCComboChildItem,
    CSCItemTemplate,
    CSCModifierDetail
} from '@tb-core/types/client-side-cart';
import { CartSummaryProduct, Product } from '@tb-core/types/products';

interface CSCItemAdapterProps {
    isHH: boolean;
    onTheSideLabel: string;
    pdpProductsJson: Product[];
    products: CartSummaryProduct[];
}
/**
 * Combines indexebdb data with PDP json data so that all information is available for the child components.
 */
export const cscItemAdapter = ({
    isHH,
    onTheSideLabel = '',
    pdpProductsJson,
    products
}: CSCItemAdapterProps) => {
    const cartLineItems: CSCItemTemplate[] = [];
    let isAllItemsInCartAvailable =
        products.length > 0 && pdpProductsJson.length > 0;
    const isBreakfastServedAtPickupTime = isBreakfastAvailable();
    products.forEach(product => {
        /**
         * match the indexeddb product to the pdp json product.
         * the pdp json product container the additional information needed on the cart page
         */
        const productMatch: Product | undefined = pdpProductsJson.find(
            ({ code }) => code === product.plu
        );

        /**
         * there should always be a match. otherwise, extended product information will not be available
         */
        if (productMatch) {
            /**
             * get extended combo item information.
             * parent combo product json does not contain modifier information for child items
             */
            const items: CSCComboChildItem[] = product.items?.length
                ? getItemDetails(
                      product.items,
                      onTheSideLabel,
                      pdpProductsJson,
                      productMatch
                  )
                : [];
            const isAnItemInComboUnavailable = items.some(
                i => i.isAvailable === false
            );

            /**
             * // get modifier information for base products
             */
            const modifiers: CSCModifierDetail[] = product.modifiers?.length
                ? getModifierDetails(
                      productMatch,
                      product.modifiers,
                      onTheSideLabel
                  )
                : [];

            const isAvailable = isProductValid(productMatch);
            const { id, plu, productGroup, qty, url, variant } = product;
            const { modifiable, name } = productMatch;
            const price = getProductPrice(
                productMatch,
                product.happierHourPrice,
                isHH,
                items,
                modifiers
            );

            /** Check if 1 or more unavailable items are in cart and also
             *  If item is available, then check if item is breakfast item, and it is not breakfast time
             *  If either one of these is the case then we want to pass that along to 'useEnableCheckout'
             */
            const isBreakfastItem = productMatch.dayPartMessages.some(
                e => e.dayPartCode.toLowerCase() === DAYPART_BREAKFAST
            );
            const isOutsideBreakfastTimeItem =
                isAvailable && !isBreakfastServedAtPickupTime && isBreakfastItem
                    ? true
                    : false;
            if (
                !isAvailable ||
                isOutsideBreakfastTimeItem ||
                isAnItemInComboUnavailable
            ) {
                isAllItemsInCartAvailable = false;
            }

            const image = productMatch.images?.[0].url;

            cartLineItems.push({
                category: productGroup,
                id,
                image,
                isAnItemInComboUnavailable,
                isAvailable,
                isOutsideBreakfastTimeItem,
                itemName: name,
                items,
                modifiable,
                modifiers,
                plu,
                price,
                qty,
                url,
                variant
            });
        }
    });
    return {
        cartLineItems,
        isAllItemsInCartAvailable
    };
};
