import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import PickupStore from '@tb-core/components/styled/pickup-store';
import StartYourOrder from '@tb-core/components/styled/start-your-order';
import { updateDataLayer } from '@tb-core/helpers/analytics/google';
import { interpolate } from '@tb-core/helpers/interpolate';
import { foodStorePageUrl } from '@tb-core/helpers/urls';
import { useClientSideCartContext } from '@tb-core/hooks/client-side-cart/use-client-side-cart-context';
import usePageContext from '@tb-core/hooks/use-page-context';
import { usePickupContext } from '@tb-core/hooks/use-pickup-context';
import useStoreContext from '@tb-core/hooks/use-store-context';
import useMatchMedia from '@tb-core/hooks/window/use-matchmedia';

import styles from './styles.module.scss';

const PickupStoreWithContext = (props: {
    collapsed?: boolean;
    isNav?: boolean;
    startLabel?: string;
}) => {
    const [showStartOrder, setShowStartOrder] = useState(true);
    const {
        changeLabel,
        choosePickupLabel,
        expirationLabel,
        locationLabel1,
        locationLabel2,
        locationLabel3,
        locationLabel4,
        startLabel1,
        startLabel2,
        startLinkText,
        startUrl
    } = usePageContext().content.pageData.topicMeta.layout;

    const {
        address,
        isValidPickupTime,
        noMethodSelected,
        noStore,
        pickupLabel,
        pickupMethodIcon
    } = usePickupContext({
        choosePickupLabel,
        noStoreLabel: locationLabel2,
        pickupAtLabel: locationLabel1,
        pickupNowDriveThruLabel: locationLabel3,
        pickupNowInStoreLabel: locationLabel4
    });

    const { storeId } = useStoreContext();
    const { local } = useClientSideCartContext();
    const router = useRouter();
    // Component is being used on the top navigation & cart, ga datalayer reflects its position & if
    // tag is for the start your order component or pickup store component.
    const gaDataLayerConfig = {
        'Analytics-Action': props.isNav ? 'Top Nav' : 'Cart>Location',
        'Analytics-Value': !noStore
            ? 'Change'
            : props.isNav
            ? 'START YOUR ORDER'
            : 'CHOOSE STORE',
        event: 'gtm.linkClick'
    };

    const handleEvent = () => {
        const ampEvent = props.isNav
            ? 'Clicked Start Your Order'
            : 'Selected Store';

        track(ampEvent, { cta: startLinkText });

        updateDataLayer({
            even: 'menu_navigation',
            link_text: !noStore
                ? 'Change'
                : props.isNav
                ? 'START YOUR ORDER'
                : 'CHOOSE STORE',
            menu_type: 'top nav'
        });
    };

    const foodUrl = !noStore
        ? interpolate(foodStorePageUrl, { storeId })
        : startUrl;

    const isMobile = useMatchMedia('(max-width: 992px)');

    useEffect(() => {
        // check to see if current route is clp, cls, pdp and cart is empty
        setShowStartOrder(
            !router.pathname.includes('/food') && local.cart.length === 0
        );
    }, [local, router]);

    return (
        <>
            {noStore ? (
                <StartYourOrder
                    gaDataLayerConfig={gaDataLayerConfig}
                    onClick={handleEvent}
                    startLabel1={startLabel1}
                    startLabel2={startLabel2}
                    startLinkText={props.startLabel || startLinkText}
                    startUrl={startUrl}
                />
            ) : (
                <>
                    {isMobile ? (
                        <div
                            className={classNames(
                                styles['pickup-store-start-order-container'],
                                {
                                    [styles[
                                        'show-start-order-inactive'
                                    ]]: !showStartOrder
                                }
                            )}
                        >
                            <PickupStore
                                {...{
                                    ...props,
                                    changeLabel,
                                    expirationLabel,
                                    gaDataLayerConfig,
                                    isValidPickupTime,
                                    noMethodSelected,
                                    pickupMethodIcon
                                }}
                                storeLabel1={pickupLabel}
                                storeLabel2={address}
                            />
                            {showStartOrder && (
                                <StartYourOrder
                                    gaDataLayerConfig={gaDataLayerConfig}
                                    onClick={handleEvent}
                                    startLabel1={startLabel1}
                                    startLabel2={startLabel2}
                                    startLinkText={
                                        props.startLabel || startLinkText
                                    }
                                    startUrl={foodUrl}
                                    storeSelected={!noStore}
                                />
                            )}
                        </div>
                    ) : (
                        <PickupStore
                            {...{
                                ...props,
                                changeLabel,
                                expirationLabel,
                                gaDataLayerConfig,
                                isValidPickupTime,
                                noMethodSelected,
                                pickupMethodIcon
                            }}
                            storeLabel1={pickupLabel}
                            storeLabel2={address}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default PickupStoreWithContext;
