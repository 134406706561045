import { useEffect, useState } from 'react';

import { isValidMenu } from '@tb-core/components/context/menu';
import useMenuContext from '@tb-core/hooks/use-menu-context';
import {
    useWebWorker,
    WebWorkerSuccessHandler
} from '@tb-core/hooks/use-web-worker';
import { CSCItem } from '@tb-core/types/client-side-cart';
import { CartSummaryProduct } from '@tb-core/types/products';
import CartValidationWorker from '@tb-core/workers/cart-validation.worker.js';

interface CartValidationWebWorkerMessage<T = any> {
    data: T[];
}

interface CartSummary {
    products: CartSummaryProduct[];
    productsInit: boolean;
}

type UseCartSummaryHook = (
    cart: CSCItem[],
    isCartInitialized: boolean
) => CartSummary;

/**
 * A products tracker which provides the menu products of a given product category.
 * @param cart The local cart
 * @param isCartInitialized boolean - is the passed in `cart` initialized yet?
 * @return products: CSCItem[]
 * @return productsInit: boolean - true once `products` is initalized
 */
export const useCartSummary: UseCartSummaryHook = (cart, isCartInitialized) => {
    const [productsString, setProducts] = useState<string>();
    const menu = useMenuContext();
    const onMessage: WebWorkerSuccessHandler<CartValidationWebWorkerMessage<
        CartSummaryProduct[]
    >> = ({ data }) => {
        setProducts(JSON.stringify(data.data));
    };
    const cartValidationWorker = useWebWorker(
        'cart-validation',
        CartValidationWorker,
        onMessage
    );
    const products: CartSummaryProduct[] = productsString
        ? JSON.parse(productsString)
        : [];

    // "Cart validation" effect
    useEffect(() => {
        if (isCartInitialized && isValidMenu(menu)) {
            // We have a cart w/ items & menu data exists,
            // validate the cart.
            cartValidationWorker?.postMessage({
                cart,
                menu
            });
        }
    }, [JSON.stringify(cart), JSON.stringify(menu), cartValidationWorker]);

    return {
        products,
        productsInit:
            isValidMenu(menu) &&
            isCartInitialized &&
            (cart.length === 0 || products.length > 0)
    };
};
