import { useCallback } from 'react';

import { minMax, MinMax } from '@tb-core/helpers/utils/min-max';

/**
 * Provides the min-max helper utility for bounding numbers to a range.
 * @example
 *      import { useMinMax } from '@tb-core/hooks';
 *
 *      const getMinMax = useMinMax({
 *          max: 100,
 *          min: 0
 *      });
 *
 *      const valueInRange = getMinMax(nextValue);
 */

export const useMinMax = ({ max, min }: Omit<MinMax, 'value'>) =>
    useCallback(
        nextValue =>
            minMax({
                max: isNaN(Number(max)) ? undefined : max,
                min: isNaN(Number(min)) ? undefined : min,
                value: nextValue
            }),
        [max, min]
    );
