import { useState } from 'react';

import { isPickupTimeInFuture } from '@tb-core/helpers/date';
import sessionStorage, { GetSelectedStore } from '@tb-core/helpers/storage';

/**
 *
 * Checks for pickup time in sessionStorage
 * compares currentTime to storePickupTime to generate a
 * isValidPickupTime boolean set in session storage
 *
 * @returns checkPickupTimeExpired, isValidPickupTime
 */
const usePickupTimeExpiry = () => {
    const [isValidPickupTime, setIsValidPickupTime] = useState(true);

    const checkPickupTimeExpired = () => {
        const { pickupTimeStamp } = GetSelectedStore() || {};

        if (pickupTimeStamp) {
            const isValid = isPickupTimeInFuture(pickupTimeStamp);
            setIsValidPickupTime(isValid);

            sessionStorage.setItems({
                isValidPickupTime: isValid
            });
        }
    };

    return { checkPickupTimeExpired, isValidPickupTime };
};

export default usePickupTimeExpiry;
