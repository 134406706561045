import classNames from 'classnames';

import styles from './styles.module.scss';

export interface LoadingSpinnerProps {
    className?: string;
}

const LoadingSpinner = ({ className }: LoadingSpinnerProps) => (
    <svg className={classNames(styles.spinner, className)} viewBox="0 0 10 10">
        <path
            fill="currentColor"
            d="M 5,0
                        A5 5 90 0 1 10,5
                        L8,5
                        A3 3 90 0 0 5,2Z"
        />
        <path
            fill="currentColor"
            opacity="0.25"
            d="M5,0
                A5 5 90 1 0 10,5
                L8,5
                A3 3 90 1 1 5,2Z"
        />
    </svg>
);

export default LoadingSpinner;
