import { devSSRProxyResolve } from '@tb-core/helpers/browser/dev-proxy-resolve';
import Fetch from '@tb-core/helpers/fetch';
import { setProviderUrl } from '@tb-core/helpers/utils/optimizely/set-provider-url';
import { nextProductUrl } from '@tb-core/next/api/urls';

export default async function getProduct(
    productId: string,
    storeId: string,
    serverSideHost?: string
) {
    let res: Response;
    const url = devSSRProxyResolve(
        serverSideHost,
        setProviderUrl(nextProductUrl),
        {
            productId,
            storeId
        }
    );

    try {
        res = await Fetch({
            host: '',
            url
        });
    } catch (e) {
        console.error('Product request failed!', e);
        return {};
    }

    if (!res.ok) {
        console.error(
            'Product request failed! Reason:',
            res.status,
            res.statusText
        );
        return {};
    }

    return res.json();
}
