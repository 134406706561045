import React, { ReactNode, useEffect, useState } from 'react';

import useStoreContext from '@tb-core/hooks/use-store-context';
import getMenu from '@tb-core/providers/get-menu';
import { Menu } from '@tb-core/types/products';

interface MenuProviderProps {
    children: ReactNode;
}

export const isValidMenu = (menu: Menu) =>
    menu.menuProductCategories.length > 0;

export const emptyMenu: Menu = {
    happierHours: undefined,
    menuProductCategories: [],
    storeSchedule: undefined
};

const MenuContext = React.createContext<Menu>(emptyMenu);
const { Consumer, Provider } = MenuContext;

const MenuProvider = ({ children }: MenuProviderProps) => {
    const [menu, setMenu] = useState<Menu>(emptyMenu);
    const { storeId } = useStoreContext();
    const fetchMenu = async () => setMenu(await getMenu(storeId));

    useEffect(() => {
        if (storeId) {
            fetchMenu();
        }
    }, [storeId]);

    return <Provider value={menu}>{children}</Provider>;
};

const MenuConsumer = Consumer;

export default MenuProvider;
export { MenuConsumer, MenuContext };
