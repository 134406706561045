import classNames from 'classnames';

import UserDropdownItems, {
    DropdownItem
} from '@tb-core/components/composites/full-toolbar/user-dropdown-items';

import styles from './full-toolbar.module.scss';

export interface UserDropdownMenuProps {
    dropdownItems: DropdownItem[];
    dropdownMenuToggle?: boolean;
    toggleHover?: () => void;
}

const UserDropdownMenu = ({
    dropdownItems,
    dropdownMenuToggle,
    toggleHover
}: UserDropdownMenuProps): JSX.Element => (
    <div
        className={classNames([
            styles['dropdown-container'],
            { [styles['dropdown-show']]: dropdownMenuToggle }
        ])}
        onMouseLeave={toggleHover}
    >
        <UserDropdownItems dropdownItems={dropdownItems} />
    </div>
);

export default UserDropdownMenu;
