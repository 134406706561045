import { useEffect, useRef, useState } from 'react';

import { LocalCart } from '@tb-core/hooks/client-side-cart/use-cart';
import useUserContext from '@tb-core/hooks/use-user-context';
import {
    getRemoteCart,
    putRemoteCart
} from '@tb-core/providers/products/cart-api';
import {
    CSCRequestBody,
    CSCResponseBody
} from '@tb-core/types/client-side-cart';

export interface RemoteCart {
    payload: Partial<CSCRequestBody>;
    syncCart: (body: CSCRequestBody) => Promise<void>;
}

export interface RemoteCartHookOptions {
    syncOnUpdate: boolean;
}

export type UseRemoteCartHook = (
    localCart: LocalCart,
    options: RemoteCartHookOptions
) => RemoteCart;

/**
 * Returns the (cross-platform-shared) remote cart, as well as a method to sync back to the remote cart.
 * @example
 *      const { payload, syncCart } = useRemoteCart();
 *      const onClick = () => {
 *          if (payload) {
 *              syncCart(payload);
 *          }
 *      };
 *
 *      console.log('remote cart', payload);
 *      // => {
 *      //        items: [{ plu, qty, modifiers }, ...restItems],
 *      //        rewards: []
 *      //    }
 */
export const useRemoteCart: UseRemoteCartHook = (
    { cart, isDirty, setDirty },
    { syncOnUpdate }
) => {
    const remoteCartRequested = useRef(false);
    const { isLoggedIn } = useUserContext();
    const [payload, setRemoteCart] = useState<CSCResponseBody>({});
    // API
    // Must be logged in for remote cart connectivity:

    const syncCart = async ({ items }: CSCRequestBody) => {
        if (isLoggedIn) {
            if (!remoteCartRequested.current && !isDirty && !items.length) {
                // GET
                remoteCartRequested.current = true;
                setRemoteCart(await getRemoteCart());
            } else if (syncOnUpdate && isDirty) {
                // PUT
                await putRemoteCart({ items });
                if (setDirty) {
                    setDirty(false);
                }
            }
        }
    };

    useEffect(() => {
        if (cart && !isDirty) {
            syncCart({ items: cart });
        }
    }, [cart, isDirty]);

    return {
        payload,
        syncCart
    };
};
