import BrandedLinkButton from '@tb-core/components/styled/links/branded-link-button';
import { RealObject } from '@tb-core/types';

import fullToolbar from '@tb-core/components/composites/full-toolbar/full-toolbar.module.scss';
import styles from './styles.module.scss';

interface StartYourOrderProps {
    gaDataLayerConfig?: RealObject;
    onClick?: () => void;
    startLabel1?: string;
    startLabel2?: string;
    startLinkText?: string;
    startUrl?: string;
    storeSelected?: boolean;
}

const StartYourOrder = ({
    gaDataLayerConfig,
    onClick = () => {},
    startLabel1 = 'Ordering pickup?',
    startLabel2 = "We'll need your location",
    startLinkText = 'Start your order',
    startUrl = '/locations',
    storeSelected
}: StartYourOrderProps) => (
    <div className={styles.container}>
        {!storeSelected && (
            <div className={styles.copy}>
                <span>{startLabel1}</span>
                <span>{startLabel2}</span>
            </div>
        )}
        <div className={fullToolbar['button-container']}>
            <BrandedLinkButton
                border={false}
                className={styles['button-override']}
                gaDataLayerConfig={gaDataLayerConfig}
                href={startUrl}
                onClick={onClick}
            >
                {startLinkText}
            </BrandedLinkButton>
        </div>
    </div>
);

export default StartYourOrder;
