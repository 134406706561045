import classNames from 'classnames';
import { FC, HTMLProps } from 'react';

import styles from './styles.module.scss';

interface TbTooltipUiProps {
    value: number | string;
}

const Tooltip: FC<TbTooltipUiProps & HTMLProps<HTMLSpanElement>> = ({
    className,
    value
}) => (
    <span className={classNames([className, styles['tooltip']])}>{value}</span>
);

export default Tooltip;
