import classNames from 'classnames';
import { SyntheticEvent } from 'react';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import Svg from '@tb-core/components/simple/svg';

import fullToolbar from './full-toolbar.module.scss';

export interface LoyaltyIconProps {
    activateLoyalty: (activate: boolean) => void;
    activeLoyalty: boolean;
    title: string;
}

// TODO: Combine LoyaltyIcon and MiniLoyaltyIcon
const LoyaltyIcon = ({
    activateLoyalty,
    activeLoyalty,
    title
}: LoyaltyIconProps) => {
    const toggleLoyalty = (e: SyntheticEvent) => {
        e.preventDefault();
        activateLoyalty(!activeLoyalty);
    };

    return (
        <>
            <span className={fullToolbar.divider}>|</span>
            <SafeAnchorWithGaEvent
                className={classNames(fullToolbar['icon-animate'], {
                    [fullToolbar.animate]: activeLoyalty === true
                })}
                gaDataLayerConfig={{
                    'Analytics-Action': 'Top Nav',
                    'Analytics-Value': 'Rewards'
                }}
                href="#"
                onClick={toggleLoyalty}
            >
                <Svg
                    className={fullToolbar.icon}
                    svgId="icon-loyalty"
                    title={title}
                />
            </SafeAnchorWithGaEvent>
        </>
    );
};

export default LoyaltyIcon;
