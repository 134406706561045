import { GeoPoint } from '@tb-core/types/location';
import { useState } from 'react';

interface SuccessProps {
    coords?: { latitude: number; longitude: number };
    timestamp: number;
}
interface FailProps {
    code: number;
    message: string;
}

interface OptionProps {
    enableHighAccuracy: boolean;
    maximumAge: number;
    timeout: number;
}

/**
 * A hook for using Navigator.geolocation. Get the user's Lat/Long, if allowed by user.
 * @example
 *      import { useGeoLocation } from '@tb-core/hooks';
 *
 *      const [coordinates, error] = useGeoLocation();
 *
 *      useEffect(() => {}, [coordinates, error]);
 */

export type GeolocationHook = () => [
    () => Promise<GeoPoint | undefined>,
    FailProps | undefined,
    () => void
];

export const useGeoLocation: GeolocationHook = () => {
    const [usersGeoPoint, setUsersGeoPoint] = useState<GeoPoint>();
    const [geoLocateError, setGeoLocateError] = useState<FailProps>();
    const options: OptionProps = {
        enableHighAccuracy: false,
        maximumAge: 30000,
        timeout: 5000
    };

    const geoSuccess = (pos: SuccessProps) => {
        if (pos.coords) {
            const coordinates: GeoPoint = {
                lat: pos.coords.latitude,
                lng: pos.coords.longitude
            };
            setUsersGeoPoint(coordinates);
            return { ...coordinates };
        }
    };

    const geoError = (err: FailProps) => {
        setGeoLocateError(err);
    };

    const getPosition = async (
        options?: OptionProps
    ): Promise<SuccessProps | FailProps> => {
        return new Promise((resolve, reject) =>
            navigator.geolocation.getCurrentPosition(resolve, reject, options)
        );
    };

    /**
     * geolocationToGeoPoint
     * @returns GeoPoint | undefined
     *   if position is cached, return that
     *   else use navigator.geolocation
     *   else throw arbitrary position error `GeolocationPositionError.POSITION_UNAVAILABLE`
     */
    const geolocationToGeoPoint = async (): Promise<GeoPoint | undefined> => {
        // if user Geolocation is cached:
        if (usersGeoPoint) {
            return { ...usersGeoPoint };
        } else if (navigator.geolocation) {
            try {
                const response = await getPosition(options);
                if ('timestamp' in response) {
                    return geoSuccess(response);
                }
                geoError(response);
            } catch (e) {
                const error =
                    e instanceof GeolocationPositionError
                        ? e
                        : {
                              code: 1,
                              message:
                                  'Origin does not have permission to use Geolocation service'
                          };
                geoError(error);
            }
        } else {
            geoError({
                code: 2,
                message: 'geolocation not in navigator'
            });
        }
    };

    const clearError = () => {
        setGeoLocateError(undefined);
    };

    return [geolocationToGeoPoint, geoLocateError, clearError];
};
