import classNames from 'classnames';

import UserDropdownItems, {
    DropdownItem
} from '@tb-core/components/composites/full-toolbar/user-dropdown-items';

import styles from './styles.module.scss';
export interface UserDropdownAsideProps {
    dropdownItems?: DropdownItem[];
    displayUserDropdown?: boolean;
}

const UserDropdownAside = ({
    dropdownItems = [],
    displayUserDropdown
}: UserDropdownAsideProps): JSX.Element => (
    <div
        className={classNames([
            styles['dropdown-container'],
            { [styles['dropdown-show']]: displayUserDropdown }
        ])}
    >
        <UserDropdownItems dropdownItems={dropdownItems} />
    </div>
);

export default UserDropdownAside;
