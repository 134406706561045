import { useContext } from 'react';

import { CartIconContext } from '@tb-core/components/context/cart-icon';

const useCartIconContext = () => {
    const ctx = useContext(CartIconContext);

    if (ctx === undefined) {
        throw new Error(
            'useCartIconContext must be used within a CartIconProvider'
        );
    }

    return ctx;
};

export default useCartIconContext;
