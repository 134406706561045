import {
    Content,
    Hyperlink
} from '@tb-core/components/styled/rich-text/index.d';
import { RealObject } from '@tb-core/types';

/**
 * Returns the type of the content formatted all lowercase with a dash seperator between words.
 * @param { contentType }
 */
export function contentType({ contentType }: any = {}): string {
    return (((contentType || {}).sys || {}).id || '')
        .replace(/([A-Z])/g, '-$1')
        .toLowerCase();
}

/**
 * Filters an object of ContentCollection arrays keyed by __typename
 * @param { Array<> }
 */
export function entriesByTypename(entries: RealObject[]) {
    return entries.reduce(
        (results, entry) => ({
            ...results,
            ...(entry && {
                [entry.__typename]: [
                    ...(results[entry.__typename] || []),
                    entry
                ]
            })
        }),
        {}
    );
}

/**
 * Filters unset content or falsy content values from the provided content values.
 * @param content
 */
export function contentValues(content: Array<Content | Hyperlink>) {
    return content.filter(({ content, value }: any) => content || !!value);
}

/**
 * Filters null items from array
 * uesd to remove DRAFT mode entries out of CTF content
 * @param content
 */
export const filterNullItems = <T extends unknown>(arr: T[]) =>
    arr.filter(item => item !== null);
