import React from 'react';

import { DivProps } from '@tb-core/types/elements/standard';
import classNames from 'classnames';

import styles from './styles.module.scss';

export type CenterProps = DivProps;

const Center = ({ children, className }: CenterProps) => (
    <div className={classNames(styles.center, className)}>{children}</div>
);

export default Center;
