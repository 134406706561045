import { useEffect, useState } from 'react';

// @TODO: refactor timeout to https://gist.githubusercontent.com/jamesfulford/de7b5e5f37d97595e0626cca6f2002e0/raw/f5fda29af248607dd2d44c2b18e094ace1a2d065/useTimeout.ts

/**
 * Returns the current height, width of the browser onResize, throttled by delay
 * @example
 *      const isMobile = useWindowResize('(max-width: 640px)');
 */
const useMatchMedia = (mediaQuery: string) => {
    const [matches, setMatches] = useState<boolean | null>(null);

    const screenTest = (e: MediaQueryList | MediaQueryListEvent) => {
        setMatches(e.matches);
    };

    useEffect(() => {
        const queryList = window.matchMedia(mediaQuery);
        queryList.addEventListener('change', screenTest);
        screenTest(queryList);
        return () => {
            queryList.removeEventListener('change', screenTest);
        };
    }, []);

    return matches;
};

export default useMatchMedia;
