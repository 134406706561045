import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import Svg from '@tb-core/components/simple/svg';

import fullToolbar from '@tb-core/components/composites/full-toolbar/full-toolbar.module.scss';
interface MiniLogoIconProps {
    isClickableLogo: boolean;
    logo: string;
    title: string;
}

const MiniLogoIcon = ({ isClickableLogo, logo, title }: MiniLogoIconProps) => {
    return isClickableLogo ? (
        <SafeAnchorWithGaEvent
            aria-label="Taco Bell Home"
            gaDataLayerConfig={{
                'Analytics-Action': 'Top Nav',
                'Analytics-Value': 'Home'
            }}
            href="/"
        >
            <Svg className={fullToolbar.tblogo} svgId={logo} title={title} />
        </SafeAnchorWithGaEvent>
    ) : (
        <Svg className={fullToolbar.tblogo} svgId={logo} title={title} />
    );
};

export default MiniLogoIcon;
