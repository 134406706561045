import classNames from 'classnames';

import SafeAnchor from '@tb-core/components/behavior/safe-anchor';
import BrandedLinkButton from '@tb-core/components/styled/links/branded-link-button';
import { makeLogoutRequest } from '@tb-core/providers/auth/logout-request';

import styles from './full-toolbar.module.scss';

export interface DropdownItem {
    button?: boolean;
    href: string;
    label: string;
}

export interface UserDropdownItems {
    dropdownItems: DropdownItem[];
}

const UserDropdownItems = ({ dropdownItems }: UserDropdownItems) => {
    const anchorUrl = (item: DropdownItem) =>
        item.label === 'Log Out' ? '#' : item.href;

    const logoutOnClickHandler = (label: string) => {
        if (label === 'Log Out') {
            makeLogoutRequest();
        }
    };

    return (
        <ul className={styles['dropdown-list']}>
            {dropdownItems?.map((item, i) => (
                <li
                    className={styles['dropdown-element']}
                    key={`dropdown-item-${i}`}
                >
                    {item.button ? (
                        <BrandedLinkButton
                            className={styles['dropdown-button']}
                            href={item?.href}
                        >
                            {item?.label}
                        </BrandedLinkButton>
                    ) : (
                        <SafeAnchor
                            className={classNames([
                                styles['dropdown-link'],
                                {
                                    [styles['dropdown-logout']]:
                                        item.label === 'Log Out'
                                }
                            ])}
                            href={anchorUrl(item)}
                            onClick={() => {
                                logoutOnClickHandler(item.label);
                            }}
                        >
                            {item?.label}
                        </SafeAnchor>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default UserDropdownItems;
