import { devProxyResolve } from '@tb-core/helpers/browser/dev-proxy-resolve';
import Fetch from '@tb-core/helpers/fetch';
import { cartUrl } from '@tb-core/next/api/urls';
import { CSCResponseBody } from '@tb-core/types/client-side-cart';

// Gets the remote cart.
export const getRemoteCart = async () => {
    let res: Response;

    try {
        res = await Fetch({
            host: '',
            url: devProxyResolve(cartUrl)
        });
    } catch (e) {
        console.error('Cart API GET request failed!', e);
        return {} as CSCResponseBody;
    }

    if (!res.ok) {
        return {} as CSCResponseBody;
    }

    return res.json() as Promise<CSCResponseBody>;
};
