import { devProxyResolve } from '@tb-core/helpers/browser/dev-proxy-resolve';
import Fetch from '@tb-core/helpers/fetch';
import { cartUrl } from '@tb-core/next/api/urls';
import { CSCRequestBody } from '@tb-core/types/client-side-cart';

// Put to the remote cart.
export const putRemoteCart = async (req?: CSCRequestBody) => {
    let res: Response;

    try {
        res = await Fetch({
            body: JSON.stringify(req),
            host: '',
            method: 'PUT',
            url: devProxyResolve(cartUrl)
        });
    } catch (e) {
        console.error('Cart API PUT request failed!', e);
        return;
    }

    if (!res.ok) {
        return;
    }

    return;
};
