export interface CurrencyProps {
    className?: string;
    prefix?: '+' | '-';
    qty?: number;
    total: number;
}

/**
 * convert number to $ currency
 * @param {number} val A currency amount
 * @returns {string}
 */
export const toDollars = (val: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
        currency: 'USD',
        minimumFractionDigits: 2,
        style: 'currency'
    });
    return formatter.format(val);
};

/**
 * Convert number to currency, or render currency string
 * @param CurrencyProps object containing the following props
 * className - used for styling
 * prependMinusSign - used to indicate lesser cost
 * prependPlusSign - used to indicate additional cost
 * qty - number of items to calculate against, 1 by default
 * total - number or string of the base amount
 * @returns JSX
 */
const Currency = ({ className, prefix, qty = 1, total }: CurrencyProps) => (
    <span className={className}>
        {!isNaN(total) && (
            <>
                {prefix}
                {toDollars((total * 100 * qty) / 100)}
            </>
        )}
    </span>
);

export default Currency;
