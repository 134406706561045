import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { webFixedVersion } from '@tb-core/helpers/next-env';

export const dataDogInitialize = (dataDogEnv: string) => {
    const dataDogClientToken = 'pubd163eb16c21e04e40d65523e7860bbcd';
    const sampleRate = ['perftest', 'prod'].indexOf(dataDogEnv) < 0 ? 100 : 15;

    // TODO: Look into removing from application completely once confirmed
    // it is not used by RS or Web team.
    datadogRum.init({
        allowedTracingOrigins: [
            'https://tacobell.com',
            /https:\/\/.*\.tacobell\.com/,
            'https://nonprod.tb-aws.com',
            /https:\/\/.*\.nonprod\.tb-aws\.com/,
            'https://www-rel-t.test.tb-aws.com',
            /https:\/\/www-rel-t\.test\.tb-aws\.com/
        ],
        applicationId: '383aa0a3-4188-4d94-bf4b-684fdce92d8a',
        clientToken: dataDogClientToken,
        env: dataDogEnv,
        replaySampleRate: 0,
        sampleRate: 0,
        service: 'app-web',
        silentMultipleInit: true,
        site: 'datadoghq.com',
        trackInteractions: true,
        trackSessionAcrossSubdomains: true,
        useSecureSessionCookie: true,
        version: webFixedVersion
    });
    datadogLogs.init({
        clientToken: dataDogClientToken,
        env: dataDogEnv,
        forwardErrorsToLogs: true,
        sampleRate,
        service: 'app-web',
        silentMultipleInit: true,
        site: 'datadoghq.com',
        trackSessionAcrossSubdomains: true,
        useCrossSiteSessionCookie: true,
        useSecureSessionCookie: true,
        version: webFixedVersion
    });
    datadogRum.addRumGlobalContext('project', 'app-web-2');
    datadogLogs.addLoggerGlobalContext('project', 'app-web-2');
    datadogLogs.logger.setLevel('warn');
};
