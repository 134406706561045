import { useEffect, useState } from 'react';

import { storeNationalId } from '@tb-core/helpers/next-env';
import { parseSelectedStoreAddress } from '@tb-core/helpers/purchase/parse-selected-store-address';
import { GetSelectedStore } from '@tb-core/helpers/storage';
import useInterval from '@tb-core/hooks/use-interval';
import usePickupTimeExpiry from '@tb-core/hooks/use-pickup-time-expiry';
import useStoreContext from '@tb-core/hooks/use-store-context';
import { Store } from '@tb-core/types';
import {
    isPickupMethodInStore,
    pickupMethodIcons,
    TimeMethod
} from '@tb-core/types/location.d';

interface UsePickupContextProps {
    choosePickupLabel?: string;
    noStoreLabel?: string;
    pickupAtLabel?: string;
    pickupNowDriveThruLabel?: string;
    pickupNowInStoreLabel?: string;
}

export const usePickupContext = ({
    choosePickupLabel,
    noStoreLabel = '',
    pickupAtLabel,
    pickupNowDriveThruLabel,
    pickupNowInStoreLabel
}: UsePickupContextProps) => {
    const { storeId, ...store } = useStoreContext();
    const selectedStore = GetSelectedStore();
    const [intervalDelay, setIntervalDelay] = useState<number | null>(null);
    const [pickupLabel, setPickupLabel] = useState('');
    const [pickupMethodIcon, setPickupMethodIcon] = useState('icon-place');
    const noMethodSelected =
        (store.storeAddress && !GetSelectedStore()?.storePickupMethod) || false;
    const noStore = !storeId || storeId === storeNationalId;
    const { checkPickupTimeExpired, isValidPickupTime } = usePickupTimeExpiry();
    const [address, setAddress] = useState('');
    useInterval(checkPickupTimeExpired, intervalDelay);

    if (!address) {
        setAddress(
            noStore ? noStoreLabel : parseSelectedStoreAddress(store as Store)
        );
    }

    useEffect(() => {
        const hasSelectedStore =
            selectedStore && Object.keys(selectedStore).length > 0;

        if (hasSelectedStore) {
            // only set interval if pickup time is later
            if (selectedStore.storePickupTimeMethod === TimeMethod.Later) {
                setIntervalDelay(10000);
            }
            // set 'pickup Label' based on storePickupTimeMethod and storePickupMethod
            switch (selectedStore.storePickupTimeMethod) {
                case TimeMethod.Later:
                    setPickupLabel(
                        `${pickupAtLabel} ${selectedStore.pickupTimeLabel}`
                    );
                    break;
                case TimeMethod.Now:
                    isPickupMethodInStore(selectedStore.storePickupMethod)
                        ? setPickupLabel(pickupNowInStoreLabel || '')
                        : setPickupLabel(pickupNowDriveThruLabel || '');
                    break;
            }
            // set icon
            setPickupMethodIcon(
                pickupMethodIcons[selectedStore.storePickupMethod]
            );
        }
    }, []);

    useEffect(() => {
        if (noMethodSelected) {
            setPickupLabel(choosePickupLabel || '');
        }
    }, [store.storeAddress]);

    useEffect(() => {
        setAddress(
            noStore ? noStoreLabel : parseSelectedStoreAddress(store as Store)
        );
    }, [storeId]);

    return {
        address,
        isValidPickupTime,
        noMethodSelected,
        noStore,
        pickupLabel,
        pickupMethodIcon
    };
};
