import { Product } from '@tb-core/types/products';

interface ProductsContent {
    products: Product[];
}

export const filterPurchaseableProducts = (
    menuProductCategories: ProductsContent[]
) =>
    menuProductCategories
        .map(({ products }: ProductsContent) =>
            products.filter(
                (item: Product) =>
                    item.isAvailableInStore &&
                    item.purchasable &&
                    item.searchable
            )
        )
        .flat();
