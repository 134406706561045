import classNames from 'classnames';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import LazyLoadSvg from '@tb-core/components/composites/lazyload/svg';
import { RealObject } from '@tb-core/types';

import styles from './styles.module.scss';

export interface PickupStoreProps {
    changeLabel: string;
    collapsed?: boolean;
    expirationLabel: string;
    gaDataLayerConfig?: RealObject;
    isValidPickupTime: boolean;
    noMethodSelected: boolean;
    pickupMethodIcon: string;
    storeLabel1?: string;
    storeLabel2?: string;
}

const PickupStore = ({
    changeLabel,
    expirationLabel,
    gaDataLayerConfig,
    isValidPickupTime = true,
    noMethodSelected,
    pickupMethodIcon,
    storeLabel1,
    storeLabel2
}: PickupStoreProps) => (
    <div className={classNames(styles['pickup-store'])}>
        <SafeAnchorWithGaEvent
            gaDataLayerConfig={gaDataLayerConfig}
            href="/locations"
        >
            <>
                {/* Full width layout */}
                <LazyLoadSvg
                    size="2em"
                    svgId={pickupMethodIcon || 'icon-place'}
                />

                <div>
                    {!isValidPickupTime ? (
                        <small className={styles.error}>
                            {expirationLabel}
                        </small>
                    ) : (
                        <small
                            className={classNames({
                                [styles.error]: noMethodSelected
                            })}
                        >
                            {storeLabel1}
                        </small>
                    )}
                    <span>{storeLabel2}</span>
                    <div className={styles['change-link']}>{changeLabel}</div>
                </div>
            </>
        </SafeAnchorWithGaEvent>
    </div>
);

export default PickupStore;
