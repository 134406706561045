/**
 * Sets up an interval and clears it after unmounting
 * @param {callback} callback - Function to call during interval
 * @param {number} delay - Number in milliseconds or null to stop it
 *
 * @example
 * useInterval(checkPickupTimeExpired, 10000)
 *
 */

import { useEffect, useRef } from 'react';

import useLayoutEffectIsomorphic from '@tb-core/hooks/use-layout-effect-isomorphic';

const useInterval = (callback: () => void, delay: number | null) => {
    const savedCallback = useRef(callback);

    useLayoutEffectIsomorphic(() => (savedCallback.current = callback), [
        callback
    ]);

    useEffect(() => {
        if (delay !== null) {
            const id: ReturnType<typeof setInterval> = setInterval(
                savedCallback.current,
                delay
            );

            return () => clearInterval(id);
        }
    }, [delay]);
};

export default useInterval;
